import React from "react";
import "./styles.scss";
import FeedCards from "../../components/Cards/FeedCards";
import Image1 from "../../assets/images/feed-mock-images/feed-1.jpg";
import Image2 from "../../assets/images/feed-mock-images/feed-2.png";
import Image3 from "../../assets/images/feed-mock-images/feed-3.jpg";
import Image4 from "../../assets/images/feed-mock-images/feed-4.jpg";
import Image5 from "../../assets/images/feed-mock-images/feed-5.jpg";

const feedMock = [
  {
    image: Image1,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
  },
  {
    image: Image2,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
  },
  {
    image: Image3,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
  },
  {
    image: Image4,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
  },
  {
    image: Image5,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
  },
];

const FeedPage: React.FC = () => {
  return (
    <div className="feed-page">
      {feedMock.map((creator, index) => (
        <FeedCards
          key={index}
          image={creator.image}
          age={creator.age}
          isVerify={creator.isVerify}
          name={creator.name}
          postTime={creator.postTime}
          rating={creator.rating}
          qnt_videos={creator.qnt_videos}
          qnt_photos={creator.qnt_photos}
          price_pack={creator.price_pack}
          views_post={creator.views_post}
        />
      ))}
    </div>
  );
};

export default FeedPage;
