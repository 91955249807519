import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import accountIconGray from "../../assets/icons/account-icon-gray.svg";
import profileIconGray from "../../assets/icons/profile-icon-gray.svg";
import identityIconGray from "../../assets/icons/identity-icon-gray.svg";
import notificationsIconGray from "../../assets/icons/notification-icon-gray.svg";
import privacyIconGray from "../../assets/icons/privacy-icon-gray.svg";
import myOffersIconGray from "../../assets/icons/offers-icon-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import Button from "../../components/Buttons/Button";
import { useUserInfo } from "../../hooks/userInfo";
import { useEffect } from "react";
import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";

const UserSettingsPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo, setUserInfo } = useUserInfo();

  useEffect(() => {
    const creatorApplyStatus = async () => {
      const responseStatus = await getCreatorStatus(userInfo.access_token);

      if (responseStatus?.status === 200) {
        setUserInfo("referral_code", responseStatus?.res.referralCode);
        setUserInfo("creator_application_status", responseStatus?.res.status);
        setUserInfo(
          "creator_application_id",
          responseStatus?.res.creatorApplicationId
        );
      } else if (responseStatus?.status === 404) {
        setUserInfo("creator_application_status", "unsolicited");
      }
    };

    creatorApplyStatus();
  }, []);

  const becameACreatorHandler = () => {
    if (userInfo.creator_application_status === "PENDING") {
      setPrincipalScreen("/wait-list-application");
    } else {
      setPrincipalScreen("/create-legal-name");
    }
  };

  return (
    <div className="user-settings">
      <PageTitle
        title={"Settings"}
        subtitle={"Configuration"}
        onBackClick={() => setPrincipalScreen("")}
      />
      {/* <AccountVerification
        labelContent={"Activate Account"}
        descriptionContent={
          "Select a subscription plan to activate your creator’s account"
        }
        showDisclaimer={true}
        disclaimerContent={`Your profile will only be visible to other’s once
					you have activated your account.`}
      /> */}
      <div className="list-container-settings margin-hor-16">
        <ListItemSecondary
          showLabel={true}
          labelContent="Account"
          showIconLeft={true}
          iconLeftContent={accountIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("/user-settings-account")}
        />
        <ListItemSecondary
          showLabel={true}
          labelContent="Profile"
          showIconLeft={true}
          iconLeftContent={profileIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("/user-settings-profile")}
        />
        <ListItemSecondary
          showLabel={true}
          labelContent="Identity Verification"
          showIconLeft={true}
          iconLeftContent={identityIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("")}
        />
        <ListItemSecondary
          showLabel={true}
          labelContent="Notifications"
          showIconLeft={true}
          iconLeftContent={notificationsIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("")}
        />
        <ListItemSecondary
          showLabel={true}
          labelContent="Privacy"
          showIconLeft={true}
          iconLeftContent={privacyIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("")}
        />
        {/* <ListItemSecondary
          showLabel={true}
          labelContent="Personal Safety"
          showIconLeft={true}
          iconLeftContent={personalSafetyIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("")}
        /> */}
        <ListItemSecondary
          showLabel={true}
          labelContent="My Offers"
          showIconLeft={true}
          iconLeftContent={myOffersIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          clickAction={() => setPrincipalScreen("")}
        />
      </div>
      {userInfo.creator_application_status === "unsolicited" && (
        <div className="became-a-creator-card margin-hor-16 padding-16">
          <h6 className="no-margin">
            <b>Start Earning</b> (Monetize Content)
          </h6>
          <p className="small-p margin-bottom">
            Apply for a creator’s account and monetize your exclusive content
            today.
          </p>
          <Button buttonStyle="secondary" onClick={becameACreatorHandler}>
            Become a Creator
          </Button>
        </div>
      )}
    </div>
  );
};

export default UserSettingsPage;
