import fetchApi from "../fetchApi";

const postAddToFavorite = async (
  token: string,
  favoriteUserId: string
) => {
  return await fetchApi({
    service: "user",
    endPoint: `/favorites/${favoriteUserId}`,
    method: "POST",
    token,
  });
};

export default postAddToFavorite;
