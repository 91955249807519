import fetchApi from "../fetchApi";

const getPublicDetails = async (token: string, userId: string) => {
  return await fetchApi({
    service: "user",
    endPoint: `/public-detail/${userId}`,
    method: "GET",
    token,
  });
};

export default getPublicDetails;
