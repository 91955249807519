import React, { useState, useEffect } from "react";
import "./styles.scss";

interface FadeCarouselProps {
  slides: Slides[];
  duration: number;
}

interface Slides {
  title: string;
  subtitle: string;
  backgroundImage: string;
  icon: string;
}

const FadeCarousel: React.FC<FadeCarouselProps> = (props) => {
  const { slides, duration } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, duration);

    return () => clearInterval(interval);
  }, [slides.length, duration]);

  return (
    <div id="fade-carousel-images">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`fade-carousel-slide ${
            index === currentIndex ? "active" : ""
          }`}
          style={{ backgroundImage: `url(${slide.backgroundImage})` }}
        >
          <div
            className="slide-background"
            style={{ backgroundImage: `url(${slide.backgroundImage})` }}
          >
            {slide.title && slide.subtitle && (
              <div className="text-container">
                <img src={slide.icon} alt="Icon" className="padding-4" />
                <h2 dangerouslySetInnerHTML={{ __html: slide.title }} />
                <p dangerouslySetInnerHTML={{ __html: slide.subtitle }} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FadeCarousel;
