import { useState } from "react";
import Header from "../../components/Headers/Header";
import "./styles.scss";
import LockIcon from "../../assets/icons/lock-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import CheckIconCirclePink from "../../assets/icons/check-circle-icon-pink.svg";

const ResetPasswordPage: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserConfirmPassword, setNewUserConfirmPassword] = useState("");

  const resetPasswordSubmit = async () => {};

  const cancelSubmit = () => {};

  return (
    <div className="reset-password">
      <Header
        headerIcon="roses-logo"
        backButtonClick={() => {}}
        title={"Reset Password"}
        subTitle={
          <>
            Your password should be at least <br /> 8 characters long
          </>
        }
      />
      <InputText
        value={currentPassword}
        placeholder="Current Password"
        onChangeInput={(e) => setCurrentPassword(e)}
      />
      <div className="separator margin-vert-24" />
      <InputText
        value={newUserPassword}
        placeholder="New Password"
        onChangeInput={(e) => setNewUserPassword(e)}
        iconRight={CheckIconCirclePink}
      />
      <InputText
        value={newUserConfirmPassword}
        placeholder="Confirm New Password"
        onChangeInput={(e) => setNewUserConfirmPassword(e)}
        iconRight={CheckIconCirclePink}
      />
      <SubText icon={LockIcon} altIcon="clock icon">
        Never share your credentials with anyone.
      </SubText>

      <Button buttonStyle="primary" onClick={resetPasswordSubmit}>
        Reset password
      </Button>
      <Button buttonStyle="quaternary" onClick={cancelSubmit}>
        Cancel
      </Button>
    </div>
  );
};

export default ResetPasswordPage;
