import React, { useState } from "react";
import "./styles.scss";
import Header from "../../components/Headers/Header";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import Toast from "../../components/Toast";
import { ClipLoader } from "react-spinners";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import InputText from "../../components/Inputs/InputText";
import { IToast } from "../../types/toast";
import twitterIcon from "../../assets/icons/buttonIcons/twitter-icon-gray.svg";
import googleIcon from "../../assets/icons/buttonIcons/google-icon-gray.svg";
import digitalIcon from "../../assets/icons/buttonIcons/digital-icon-gray.svg";
import postUserAuth from "../../api/auth/postLogin";
import * as jwtDecode from "jwt-decode";
import { IInfoToken } from "../../types/infoToken";
import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";
import { useNavigate } from "react-router-dom";

const AccountLoginPage: React.FC = () => {
  //STATES
  const [hasError, setHasError] = useState(false);
  const [emailInputWarning, setInputWaning] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, setUserInfo } = useUserInfo();
  const [toastErrorMsg, setToastErrorMsg] = useState("");

  //HOOKS
  const navigate = useNavigate();
  const { setComponent } = useLoginSignUpNavigation();

  const validateEmail = (email: string): boolean => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const recoveryEmailSubmit = () => {
    if (validateEmail(userEmail)) {
    } else {
      setToastConfig({
        type: "error",
        title: "invalid email",
        description: "Add a valid email",
      });

      setToastShow(true);
      setInputWaning(true);

      setTimeout(() => {
        setToastShow(false);
        setInputWaning(false);
      }, 3000);
    }
  };

  const loginSubmit = async () => {
    setIsLoading(true);

    const prepareData = {
      email: userEmail,
      password: userPassword,
    };

    let responseAuth = await postUserAuth(prepareData);

    switch (responseAuth.status) {
      case 200:
        let response = await responseAuth.res;
        let access_token = response.access_token;
        const userInfoToken = (await jwtDecode.jwtDecode(
          access_token
        )) as IInfoToken;

        setUserInfo("access_token", access_token);
        setUserInfo("display_name", userInfoToken?.validUser.displayname);
        setUserInfo("account_type", userInfoToken?.validUser.role);
        setUserInfo("user_phone", userInfoToken?.validUser.phone);
        setUserInfo("user_email", userInfoToken?.validUser.email);
        setUserInfo("user_id", userInfoToken?.validUser.userId);

        const responseStatus = await getCreatorStatus(access_token);

        if (responseStatus?.status === 200) {
          setUserInfo("referral_code", responseStatus?.res.referralCode);
          setUserInfo("creator_application_status", responseStatus?.res.status);
          setUserInfo(
            "creator_application_id",
            responseStatus?.res.creatorApplicationId
          );
        }

        if (
          responseStatus?.res.status === "APPROVED" &&
          userInfoToken?.validUser.role.toLocaleLowerCase() === "consumer"
        ) {
          setComponent("/welcome-user");
        } else {
          navigate("/app");
        }

        break;
      case 401:
        setHasError(true);
        setIsWarning(true);
        setToastErrorMsg("Check your credentials and, please,  try again");

        setTimeout(() => {
          setIsWarning(false);
          setHasError(false);
        }, 4000);
        break;
    }

    setIsLoading(false);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      loginSubmit();
    }
  };

  return (
    <div className="account-login-page">
      <div>
        <Header
          title="Welcome!"
          titleClass="header"
          headerIcon="roses-logo"
          subTitle="For your security, please log in to continue accessing our platform."
        />
        <div className="login-container margin-top-24">
          <InputText
            value={userEmail.toLowerCase()}
            inputType="email"
            placeholder="Email"
            textTransform="none"
            onChangeInput={(e) => setUserEmail(e)}
            isWarning={emailInputWarning}
            className="no-margin"
          />
          <InputText
            inputType="password"
            value={userPassword}
            placeholder="Password"
            onChangeInput={(e) => setUserPassword(e)}
            onKeyDown={onKeyDown}
            isWarning={isWarning}
            className="no-margin"
          />
          <Button
            disabled={hasError || isLoading}
            buttonStyle="tertiary"
            onClick={loginSubmit}
          >
            {isLoading ? (
              <ClipLoader color={"#fff"} loading={true} size={15} />
            ) : (
              "Login"
            )}
          </Button>

          <div className="oauth-buttons">
            <Button
              disabled={hasError || isLoading}
              buttonStyle="icon"
              onClick={loginSubmit}
            >
              {isLoading ? (
                <ClipLoader color={"#fff"} loading={true} size={15} />
              ) : (
                <img src={twitterIcon} />
              )}
            </Button>
            <Button
              disabled={hasError || isLoading}
              buttonStyle="icon"
              onClick={loginSubmit}
            >
              {isLoading ? (
                <ClipLoader color={"#fff"} loading={true} size={15} />
              ) : (
                <img src={googleIcon} />
              )}
            </Button>
            <Button
              disabled={hasError || isLoading}
              buttonStyle="icon"
              onClick={loginSubmit}
            >
              {isLoading ? (
                <ClipLoader color={"#fff"} loading={true} size={15} />
              ) : (
                <img src={digitalIcon} />
              )}
            </Button>
          </div>
        </div>
      </div>
      <div className="buttons-container margin-top-24">
        <Button
          disabled={hasError || isLoading}
          buttonStyle="quaternary"
          onClick={() => setComponent("/reset-password")}
        >
          Forgot Password
        </Button>
        <Button
          disabled={hasError || isLoading}
          buttonStyle="primary"
          onClick={() => setComponent("/create-account")}
        >
          Create account
        </Button>
        <SubText>
          By tapping any of the actions above, you acknowledge that you are over
          the age of 18 and agree to our Terms of Services. Learn how we process
          your data in our Privacy Policy.
        </SubText>
      </div>
      <Toast
        type="error"
        isVisible={hasError}
        setIsVisible={setHasError}
        title="Error"
        description="Please fill all the fields correctly."
      />
    </div>
  );
};

export default AccountLoginPage;
