import React, { useRef, useState, useEffect } from "react";
import "./styles.scss";

interface CodeVerificationProps {
  fieldsNumber?: number;
  valuesCodesCallback: (a: string) => void;
  isWarning?: boolean;
  onEnterPress?: () => void;
}

const CodeVerification: React.FC<CodeVerificationProps> = (props) => {
  const {
    fieldsNumber = 5,
    valuesCodesCallback,
    isWarning,
    onEnterPress,
  } = props;
  const [fields, setFields] = useState<string[]>(Array(fieldsNumber).fill(""));
  const inputsRef = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    valuesCodesCallback(fields.join(""));
  }, [fields, valuesCodesCallback]);

  const handleChange = (index: number, value: string) => {
    if (value.length <= 1) {
      const updatedFields = [...fields];
      updatedFields[index] = value;
      setFields(updatedFields);

      if (index < inputsRef.current.length - 1 && value) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && fields[index] === "") {
      if (index > 0) {
        inputsRef.current[index - 1].focus();
        const updatedFields = [...fields];
        updatedFields[index - 1] = "";
        setFields(updatedFields);
      }
    } else if (e.key === "Enter" && fields.every((field) => field !== "")) {
      onEnterPress?.();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const paste = e.clipboardData.getData("text");
    if (paste.length === fieldsNumber) {
      const updatedFields = paste.split("");
      setFields(updatedFields);
      updatedFields.forEach((value, index) => {
        if (inputsRef.current[index]) {
          inputsRef.current[index].value = value;
        }
      });
      valuesCodesCallback(updatedFields.join(""));
    }
  };

  return (
    <div id="code-verification" className="margin-vert-16">
      {fields.map((field, index) => (
        <input
          className={isWarning ? "warning-input" : ""}
          key={index}
          placeholder="-"
          type="tel"
          value={field}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onPaste={handlePaste}
          maxLength={1}
          ref={(el) => (inputsRef.current[index] = el as HTMLInputElement)}
        />
      ))}
    </div>
  );
};

export default CodeVerification;
