import React, { useEffect, useState } from "react";
import "./styles.scss";

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: OptionProps[];
  defaultValueText: string;
  isWarning?: boolean;
}

interface OptionProps {
  label: string;
  value: string;
}

const Select: React.FC<SelectProps> = (props) => {
  const { options, defaultValueText, value, onChange, isWarning, ...rest } =
    props;
  const [waningInput, setWarningInput] = useState(false);

  useEffect(() => {
    setWarningInput(isWarning as boolean);

    setTimeout(() => {
      setWarningInput(false);
    }, 3000);
  }, [isWarning]);

  return (
    <select
      id="select-default"
      className={`margin-vert-16 ${waningInput ? "isWarning" : ""}`}
      value={value ?? ""}
      onChange={onChange}
      {...rest}
    >
      <option value="" label={defaultValueText}></option>
      {options.map((option, index) => (
        <option key={index} id="option-default" value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default Select;
