import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./styles.scss";
import Button from "../../Buttons/Button";

import rosesLogo from "../../../assets/icons/logos/roses-modal-logo.png";
import sphereIconPink from "../../../assets/icons/sphere-item-icon-pink.svg";
import saveIconWhite from "../../../assets/icons/save-icon-white.svg";

interface ModalPwaProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalPwa: React.FC<ModalPwaProps> = ({ isOpen, setIsOpen }) => {
  const [widescreen, setWidescreen] = useState(window.innerWidth);

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidescreen(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isOpen && <div className="modal-overlay"></div>}
      <dialog id="modal-pwa" className="padding-40" open={isOpen}>
        <div className="modal-content">
          <div className="logo-wrapper">
            <img src={rosesLogo} alt="Roses Logo" />
            <h5 className="no-margin-top">
              ROSES<sup>™</sup>
            </h5>
          </div>
          <p className="caption no-padding no-margin">
            To add this web app to the home screen:
          </p>
          {widescreen >= 768 ? (
            <>
              <div className="list-wrapper">
                <img className="sphere-icon" src={sphereIconPink} alt="" />
                <p className="caption small-padding tiny-margin">
                  Open{" "}
                  <span className="text-highlight--white">
                    https://roses.vip
                  </span>
                  <br /> on your mobile browser
                </p>
              </div>
              <hr className="no-margin" />
            </>
          ) : null}
          <div className="list-wrapper">
            <img src={sphereIconPink} alt="" />
            <p className="caption small-padding tiny-margin">Tap the button:</p>
            <img className="save-icon margin-left-8" src={saveIconWhite} alt="" />
          </div>
          <hr className="no-margin" />
          <div className="list-wrapper">
            <img src={sphereIconPink} alt="" />
            <p className="caption small-padding tiny-margin">
              Then select{" "}
              <span className="text-highlight--white">
                "Add to Home Screen"
              </span>
            </p>
          </div>
          <Button
            buttonStyle="quaternary"
            onClick={handleClose}
            children={"Close"}
          />
        </div>
      </dialog>
    </>
  );
};

export default ModalPwa;
