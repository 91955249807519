// import { servicesApi } from "../fetchApi/services-api";

import fetchApi from "../fetchApi";

// export type CreateUserProps = ReturnType<
//   ReturnType<typeof CreateUser>["postCreateUser"]
// >;

// const CreateUser = () => {
//   const apiRoute = `${servicesApi("auth")}/register`;

//   const postCreateUser = async (data: {
//     displayname: string;
//     phone: string;
//     password: string;
//     email?: string;
//     gender?: string;
//     preferedGenders?: string[];
//   }) => {
//     try {
//       const response = await fetch(`${apiRoute}`, {
//         method: "POST",
//         body: JSON.stringify(data),
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`API call failed with status ${response.status}`);
//       }

//       const jsonData = await response.json();

//       return {
//         status: response.status,
//         data: jsonData,
//       };
//     } catch (error) {
//       return {
//         status: "error",
//         //@ts-ignore
//         message: error.message,
//       };
//     }
//   };

//   return {
//     postCreateUser,
//   };
// };

// export default CreateUser();

const createUser = async (data: {
  displayname: string;
  email: string;
  password: string;
  phone?: string;
  gender?: string;
  preferedGenders?: string[];
}) => {
  return await fetchApi({
    service: "auth",
    endPoint: "/register",
    method: "POST",
    data,
  });
};

export default createUser;
