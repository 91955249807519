   
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ListItem from "../../components/Lists/ListItem";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import identityIconGray from "../../assets/icons/identity-icon-gray.svg";
import notificationsIconGray from "../../assets/icons/notification-icon-gray.svg";
import privacyIconGray from "../../assets/icons/privacy-icon-gray.svg";
import personalSafetyIconGray from "../../assets/icons/shieldIcons/personal-icon-gray.svg";
import myOffersIconGray from "../../assets/icons/offers-icon-gray.svg";
import Button from "../../components/Buttons/Button";
 
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useNavigate } from "react-router-dom";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";

const UserSettingsAccountPage: React.FC = () => {
  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const { setComponent, component } = useLoginSignUpNavigation();
  const navigate = useNavigate();

  const logoutHandler = () => {
    sessionStorage.clear();
    localStorage.clear();
    setPrincipalScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setComponent("");
    navigate("/");
  };

  return (
    <div className="user-settings-account">
      <PageTitle
        title={"Account"}
        subtitle={"Manage Account"}
        onBackClick={() => setPrincipalScreen("/user-settings")}
        hasCloseIcon={false}
      />
      <div className="list-container-user-settings margin-hor-16">
        <ListItemSecondary
          showLabel={true}
          labelContent="Identity Verification"
          showIconLeft={true}
          iconLeftContent={identityIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
        />
        <ListItemSecondary
          showLabel={true}
          labelContent="Language"
          showIconLeft={true}
          iconLeftContent={notificationsIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("/user-settings-language")}
        />
        <ListItemSecondary
          showLabel={true}
          labelContent="Password & Security"
          showIconLeft={true}
          iconLeftContent={privacyIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() =>
            setPrincipalScreen("/user-settings-change-password")
          }
        />
        <ListItemSecondary
          showLabel={true}
          labelContent="Account Privacy"
          showIconLeft={true}
          iconLeftContent={personalSafetyIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          showSeparator={true}
          clickAction={() => setPrincipalScreen("/user-settings-privacy")}
        />
        <ListItemSecondary
          showLabel={true}
          labelContent="Manage Account"
          showIconLeft={true}
          iconLeftContent={myOffersIconGray}
          showIconBorderLeft={true}
          showIconRight={true}
          iconRightContent={chevronRightIcon}
          clickAction={() =>
            setPrincipalScreen("/user-settings-manage-account")
          }
        />
      </div>
       
        <Button buttonStyle="secondary" onClick={logoutHandler}>
          Logout
        </Button>
        
    </div>
  );
};

export default UserSettingsAccountPage;
