import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import Desk from "../../components/Wrappers/Desk";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { ICatalogResponse } from "../../types/catalog";
import { useUserInfo } from "../../hooks/userInfo";
import { ToggleChatOpenContext } from "../../contexts/toggleChatOpen";
import { useNavigate } from "react-router-dom";
import getUserPreferences from "../../api/userPreferences/getUserPreferences";
import ModalInitialPreferences from "../../components/Modals/ModalInitialPreferences";
import getUserInfo from "../../api/userInfo/getUserInfo";
import getListUserChats from "../../api/chat/getListUserChats";
import { Chat, Message } from "../../types/chatInfo";
import { MomentsDetails } from "../../types/moments";
import getListFavorites from "../../api/favorite/getListFavorites";
import { useWebSocket } from "../../contexts/webSocketContext";
import newMessageNotification from "../../assets/sounds/notification-01.MP3";

type IMessagePayload = {
  eventType: string;
  payload: Message;
};

const Homepage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const navigate = useNavigate();
  const { isChatPageOpen } = useContext(ToggleChatOpenContext);
  const { addChatListener, removeListener, getMessages, getChat } =
    useWebSocket();

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const [profileAvatar, setProfileAvatar] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasANewMessage, setHasANewMessage] = useState(false);
  const [moments, setMoments] = useState<MomentsDetails[]>([]);

  useEffect(() => {
    const handleNewMessage = (newMessage: any) => {
      if (newMessage.payload.user.userId !== userInfo.user_id) {
        playNotificationSound();
      }
    };

    addChatListener(handleNewMessage);

    return () => {
      removeListener("events", handleNewMessage);
    };
  }, [addChatListener, removeListener]);

  const playNotificationSound = () => {
    const audio = new Audio(newMessageNotification);
    audio.play().catch((error) => {
      console.log("Error trying to play the audio:", error);
    });
  };

  useEffect(() => {
    const getUserInfoRequest = async () => {
      const response = await getUserInfo(userInfo.access_token);

      console.log(response?.res?.message?.statusCode);

      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
            navigate("/app-login");
          } else {
            setProfileAvatar(response.res?.photos[0]?.url);
          }
          break;
        case 401:
          navigate("/app-login");
          break;

        default:
          navigate("/app-login");
          break;
      }
    };

    const messageInChatRequest = async () => {
      const response = await getListUserChats(userInfo.access_token);

      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
            navigate("/app-login");
          } else {
            if (
              response?.res?.filter((chat: Chat) => chat?._count.messages !== 0)
                .length > 0
            ) {
              setHasANewMessage(true);
            }
          }
          break;

        case 401:
          navigate("/app-login");
          break;

        default:
          navigate("/app-login");
          break;
      }
    };

    const favoriteCreators = async () => {
      const response = await getListFavorites(userInfo.access_token);

      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
            navigate("/app-login");
          } else {
            const favoritesIds = response.res.map(
              (profile: { userId: any }) => profile.userId
            );

            if (favoritesIds.length > 0) {
            }
          }

          break;

        case 401:
          navigate("/app-login");
          break;

        default:
          navigate("/app-login");
          break;
      }
    };

    favoriteCreators();
    getUserInfoRequest();
    messageInChatRequest();
  }, [userInfo]);

  const handleInitialPreferences = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const requestUserPreferences = async () => {
      const response = await getUserPreferences(userInfo.access_token);

      switch (response.status) {
        case 200:
          if (response.res.preferedGenders?.length === 0) {
            handleInitialPreferences();
          } else {
            setIsModalOpen(false);
          }
          break;
        case 401:
          navigate("/app-login");
          break;

        default:
          navigate("/app-login");
          break;
      }
    };
    requestUserPreferences();
  }, []);

  const catalogHandlerCallback = async (profile: ICatalogResponse) => {
    if (isChatPageOpen) {
      // setSecondaryScreen("");
      setTimeout(() => {
        setSecondaryScreen("/personal-chat", { userId: profile.userId });
      }, 0);
    } else {
      setSecondaryScreen("");
    }
    setTertiaryScreen("");
    setPrincipalScreen("/interaction", { userId: profile.userId });
  };

  const clickProfileAvatar = () => {
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("/user-settings");
  };

  const onChangeInputSearch = () => {};

  const clickMoment = (userId: string) => {};

  const favoritesClickHandler = () => {
    setTertiaryScreen("/feed");
  };

  return (
    <>
      <Desk
        clickProfileAvatar={clickProfileAvatar}
        profileAvatar={profileAvatar}
        catalogHandlerCallback={catalogHandlerCallback}
        clickMoment={clickMoment}
        principalMain={componentPrincipal}
        secondaryMain={componentSecondary}
        tertiaryMain={componentTertiary}
        hasANewMessage={hasANewMessage}
        onChangeInputSearch={onChangeInputSearch}
        valueInputSearch=""
        catalogMain={mainScreen}
        favoritesClickHandler={favoritesClickHandler}
        momentsList={moments}
      />
      <ModalInitialPreferences
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </>
  );
};

export default Homepage;
