import React, { useEffect, useState } from "react";
import "./styles.scss";
import checkCircleGreenIcon from "../../assets/icons/check-circle-green.svg";
import unionYellowIcon from "../../assets/icons/union-yellow.svg";
import unionGrayIcon from "../../assets/icons/union-gray.svg";
import unionPeachIcon from "../../assets/icons/union-peach.svg";

interface ToastProps {
  type: "error" | "success" | "warning" | "info" | undefined;
  title: string;
  description: string;
  isVisible: boolean;
  setIsVisible: (a: boolean) => void;
}

const Toast: React.FC<ToastProps> = ({
  type,
  title,
  description,
  isVisible,
  setIsVisible,
}) => {
  const [toastState, setToastState] = useState("" as string);

  useEffect(() => {
    if (isVisible) {
      setToastState("show");
    } else {
      setToastState("hide");
    }
  }, [isVisible]);

  useEffect(() => {
    if (toastState === "hide") {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [setIsVisible, toastState]);

  let iconSource;
  switch (type) {
    case "success":
      iconSource = checkCircleGreenIcon;
      break;
    case "warning":
      iconSource = unionYellowIcon;
      break;
    case "info":
      iconSource = unionGrayIcon;
      break;
    case "error":
    default:
      iconSource = unionPeachIcon;
      break;
  }

  return (
    <div className={`toastContainer ${toastState}`} role="">
      <div className="iconContainer margin-right-16">
        <img
          src={iconSource}
          alt={`${type} icon`}
          style={{
            width: 19,
            height: 19,
          }}
        />
      </div>
      <div className="textContainer">
        <p className="textPrimary margin-bottom-4">{title}</p>
        <p className="textSecondary">{description}</p>
      </div>
    </div>
  );
};

export default Toast;
