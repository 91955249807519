import fetchApi from "../fetchApi";

const getCreatorStatus = async (token: string) => {
  return await fetchApi({
    service: "user",
    endPoint: "/creator-application",
    method: "GET",
    token,
  });
};

export default getCreatorStatus;
