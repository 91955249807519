import fetchApi from "../fetchApi";

export interface BodyDataRequest {
  gender?: string;
  bio?: string;
  birthDate?: Date;
  height?: number;
  weight?: number;
  maritalStatus?: string;
  occupation?: string;
  netWorth?: string;
  profileEyeColor?: string;
  profileHairColor?: string;
  profileNationality?: string;
  profileEthnicity?: string;
  profileLanguages?: string[];
}

const patchProfileDetails = async (
  token: string,
  type: string,
  name: string | string[] | number
) => {
  const data = { [type]: name };
  return await fetchApi({
    service: "user",
    endPoint: "/profile",
    method: "PATCH",
    token,
    data,
  });
};

export default patchProfileDetails;
