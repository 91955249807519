import Header from "../../components/Headers/Header";
   
import "./styles.scss";
import InfoIcon from "../../assets/icons/union-gray.svg";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import RightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
 
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import MembershipUpdateStatusAdminApi from "../../api/patchMembershipApplicationAdminStatus";
import { useUserInfo } from "../../hooks/userInfo";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";

const WaitListApplicationPage: React.FC = () => {
  const [referrals, setReferrals] = useState(0);
  const [, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo, setUserInfo } = useUserInfo();

  useEffect(() => {
    const creatorApplyStatus = async () => {
      const responseStatus = await getCreatorStatus(userInfo.access_token);

      if (responseStatus?.status === 200) {
        setUserInfo("referral_code", responseStatus?.res.referralCode);
        setUserInfo("creator_application_status", responseStatus?.res.status);
        setUserInfo(
          "creator_application_id",
          responseStatus?.res.creatorApplicationId
        );
      }
    };

		creatorApplyStatus()
  }, []);

  const approveRegisterSubmit = async () => {
    setIsLoading(true);
    const approveResponse = await MembershipUpdateStatusAdminApi(
      userInfo.user_phone
    );

    switch (approveResponse?.status) {
      case 200:
        setUserInfo("user_id", approveResponse?.res.id);
        setUserInfo("display_name", approveResponse?.res.displayName);
        setUserInfo("user_phone", approveResponse?.res.phone);
        setUserInfo("instagram", approveResponse?.res.instagramUser);
        setUserInfo("account_type", approveResponse?.res.type);

        if (approveResponse.res.status === "APPROVED") {
          setPrincipalScreen("/welcome-user");

        } else {
          console.error(
            "Unexpected response from update operation",
            approveResponse
          );
        }
        break;
      default:
        console.error(
          "Unexpected response from update operation",
          approveResponse
        );
        break;
    }
  };

  const renderApproveButton = () => {
    if (
      userInfo.user_phone === "5511967700452" ||
      userInfo.user_phone === "14074464664" ||
      userInfo.user_phone === "13053704780" ||
      userInfo.user_phone === "5545999640276" ||
      userInfo.user_phone === "5511914013654" ||
      userInfo.user_phone === "5519984484218"
    ) {
      return (
        <>
          <Button buttonStyle="primary" onClick={approveRegisterSubmit}>
            Approve
          </Button>
        </>
      );
    }
  };

  const backHomeHandler = async () => {
    setPrincipalScreen("/user-settings");
  };

  return (
    <div className="wait-list-application margin-hor-24">
      <div>
        <Header
          headerIcon="roses-logo"
          title={
            <>
              Thank you,
              <br />
              Your application is <br />
              currently on our waitlist.
            </>
          }
          subTitle={`We continually review our waitlist and will send you a push
					 notification if there are any changes to your application status.`}
        />
        <div className="list-container">
          <ListItemSecondary
            showLabel
            showValue
            showIconRight
            labelContent="My Information"
            valueContent={userInfo.display_name}
            iconRightContent={RightIcon}
            showSeparator
            clickAction={() => setPrincipalScreen("/user-information")}
            className="information-item"
          />
          <ListItemSecondary
            showLabel
            showValue
            showIconRight
            labelContent="Add referrals"
            valueContent={`${referrals} referrals`}
            iconRightContent={RightIcon}
            clickAction={() => setPrincipalScreen("/add-referrals")}
            className="information-item"
          />
        </div>
      </div>

      <SubText
        icon={InfoIcon}
        altIcon="info icon"
        className="subtext-info"
        fontSize="12px"
      >
        If you would like to list members to support your application, you can
        do so by adding referrals or by inviting friends above.
      </SubText>

       
        {renderApproveButton()}
        <Button buttonStyle="secondary" onClick={backHomeHandler}>
          Close
        </Button>
        
    </div>
  );
};

export default WaitListApplicationPage;
