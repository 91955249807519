import { servicesApi } from "../fetchApi/services-api";


export type PhoneChecker = ReturnType<
  ReturnType<typeof VerifyPhoneSMS>["createVerification"]
>;

const VerifyPhoneSMS = () => {
  const smsRoute = `${servicesApi("smsCode")}/send`;
  const smsVerifyRoute = `${servicesApi("smsCode")}/verify`;

  const createVerification = (phone: string) => {
    const requestCode = () =>
      fetch(`${smsRoute}`, {
        method: "POST",
        body: JSON.stringify({ phone }),
        headers: {
          ["Content-Type"]: "application/json",
        },
      });

    const verifyCode = (code: string) =>
      fetch(`${smsVerifyRoute}`, {
        method: "POST",
        body: JSON.stringify({ phone, code }),
        headers: {
          ["Content-Type"]: "application/json",
        },
      });

    const getPhone = () => phone;

    return {
      requestCode,
      verifyCode,
      getPhone,
    };
  };

  return {
    createVerification,
  };
};

export default VerifyPhoneSMS();



// import fetchApi from "../fetchApi";

// const createVerification = async (phone: string) => {
//   return await fetchApi({
//     service: "smsCode",
//     endPoint: "/send",
//     method: "POST",
//     data: { phone },
//   });
// };

// const verifyCode = async (phone: string, code: string) => {
//   return await fetchApi({
//     service: "smsCode",
//     endPoint: "/verify",
//     method: "POST",
//     data: { phone, code },
//   });
// };

// const smsVerificationService = {
//   createVerification,
//   verifyCode,
// };

// export default smsVerificationService;
