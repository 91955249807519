import { createContext, useContext, useState, ReactNode } from "react";
import { IUserInfo } from "../types/userInfo";

interface IUserInfoContext {
  userInfo: IUserInfo;
  setUserInfo: (key: keyof IUserInfo, value: string) => void;
}

const UserInfoContext = createContext<IUserInfoContext | undefined>(undefined);

const UserInfoProvider = ({ children }: { children: ReactNode }) => {
  const initialUserInfo: IUserInfo = {
    access_token: sessionStorage.getItem("roses-access-token") || "",
    account_type: localStorage.getItem("account_type") || "",
    instagram: localStorage.getItem("instagram") || "",
    user_phone: localStorage.getItem("user_phone") || "",
    user_email: localStorage.getItem("user_email") || "",
    user_id: localStorage.getItem("user_id") || "",
    legal_name: localStorage.getItem("legal_name") || "",
    country_code: localStorage.getItem("country_code") || "",
    display_name: localStorage.getItem("display_name") || "",
    bio: localStorage.getItem("bio") || "",
    news_letter:
      JSON.parse(localStorage.getItem("news_letter") as string) || "",
    creator_application_id:
      localStorage.getItem("creator_application_id") || "",
    referral_code: localStorage.getItem("referral_code") || "",
    creator_application_status:
      localStorage.getItem("creator_application_status") || "",
  };

  const [userInfo, setUserInfoState] = useState<IUserInfo>(initialUserInfo);

  const setUserInfo = (key: keyof IUserInfo, value: string) => {
    setUserInfoState((prevUserInfo) => ({
      ...prevUserInfo,
      [key]: value,
    }));
    if (key === "access_token") {
      sessionStorage.setItem("roses-access-token", value);
    } else {
      localStorage.setItem(key, value);
    }
  };

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
};

const useUserInfo = () => {
  const context = useContext(UserInfoContext);

  if (context === undefined) {
    throw new Error("useUserInfo must be used within a UserInfoProvider");
  }

  return context;
};

export { UserInfoProvider, useUserInfo };
