import React, { ReactNode } from "react";
import "./styles.scss";
import LogoRosesIconPink from "../../../assets/icons/logos/logo-rose-icon-pink.svg";
import LeftArrow from "../../../assets/icons/navigationIcons/arrow-left-gray.svg";
import LeftArrowWhite from "../../../assets/icons/navigationIcons/arrow-left-white.svg";
import Bell from "../../../assets/icons/bell-pink.svg";
import Heart from "../../../assets/icons/heartIcons/heart-pink.svg";
import PinIcon from "../../../assets/icons/location-pink.svg";
import TitleText from "../../Texts/TitleText";
import GlobePink from "../../../assets/icons/globe-pink.svg";
import AirPlanePink from "../../../assets/icons/air-plane-pink.svg";
import StarPink from "../../../assets/icons/star-outline.svg";
import GridPink from "../../../assets/icons/grid-pink.svg";
import FirePink from "../../../assets/icons/fire-pink.svg";
import RosesLogoHollow from "../../../assets/icons/logos/logo-hollow-pink.svg";
import CryptoIconPink from "../../../assets/icons/crypto-icon-pink.svg";
import PercentIconPink from "../../../assets/icons/percent-icon-pink.svg";
import ImageIconPink from "../../../assets/icons/photo.svg";
import VideoIconPink from "../../../assets/icons/vide-pink.svg";
import LingerieIconPink from "../../../assets/icons/lingerie-icon-pink.svg";
import DigitalPrint from "../../../assets/icons/digital-print-icon-pink.svg";
import ShieldIconPink from "../../../assets/icons/shieldIcons/security.svg";
import BallonIconPink from "../../../assets/icons/ballon-icon-pink.svg";

interface HeaderProps {
  title?: ReactNode;
  tagTitle?: "title" | "h1" | "h2" | "h3" | "h4";
  subTitle?: ReactNode;
  headerIcon?:
    | "air-plane"
    | "back-button"
    | "back-button-white"
    | "roses-logo"
    | "bell"
    | "globe"
    | "heart"
    | "pin-icon"
    | "star"
    | "grid"
    | "fire"
    | "roses-hollow"
    | "percent"
    | "crypto"
    | "image"
    | "video"
    | "lingerie"
    | "digital-print"
    | "shield"
    | "ballon"
    | undefined;
  backButtonClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
  marginTop?: number;
  hasGapBetweenTitleAndSub?: boolean;
  titleClass?: "header";
}

const Header: React.FC<HeaderProps> = (props) => {
  const {
    title,
    subTitle,
    headerIcon,
    backButtonClick,
    marginTop,
    hasGapBetweenTitleAndSub = true,
    tagTitle,
    titleClass,
  } = props;

  const switchIcon = () => {
    switch (headerIcon) {
      case "air-plane":
        return AirPlanePink;
      case "back-button":
        return LeftArrow;
      case "back-button-white":
        return LeftArrowWhite;
      case "bell":
        return Bell;
      case "grid":
        return GridPink;
      case "fire":
        return FirePink;
      case "globe":
        return GlobePink;
      case "heart":
        return Heart;
      case "pin-icon":
        return PinIcon;
      case "roses-logo":
        return LogoRosesIconPink;
      case "star":
        return StarPink;
      case "crypto":
        return CryptoIconPink;
      case "percent":
        return PercentIconPink;
      case "roses-hollow":
        return RosesLogoHollow;
      case "image":
        return ImageIconPink;
      case "lingerie":
        return LingerieIconPink;
      case "video":
        return VideoIconPink;
      case "digital-print":
        return DigitalPrint;
      case "shield":
        return ShieldIconPink;
      case "ballon":
        return BallonIconPink;

      default:
        return undefined;
    }
  };

  const icon = switchIcon();

  return (
    <header
      id="header-default"
      className={hasGapBetweenTitleAndSub ? "gap-between" : ""}
      style={{ marginTop: marginTop }}
    >
      {headerIcon && (
        <div className="icon-container">
          <img
            src={icon}
            alt={`${
              headerIcon === "back-button" ? "left arrow icon" : "Roses Logo"
            }`}
            onClick={
              headerIcon === "back-button" || headerIcon === "back-button-white"
                ? backButtonClick
                : undefined
            }
          />
        </div>
      )}
      <div>
        <TitleText size={tagTitle} titleClass={titleClass}>
          {title}
        </TitleText>
        {subTitle && <p className="description">{subTitle}</p>}
      </div>
    </header>
  );
};

export default Header