import React, { ReactNode, useEffect, useState } from "react";
import "./styles.scss";
import HeaderHomePage from "../../Headers/HeaderHomePage";
import CardsCatalog from "../../Cards/CardsCatalog";
import { useDeskNavigation } from "../../../hooks/useDeskNavigation";
import Moments from "../../Moments";
import { MomentsDetails } from "../../../types/moments";
import ButtonNavigation from "../../Buttons/ButtonNavigation";
import stories from "../../../mocks/stories";

interface DeskProps {
  principalMain?: ReactNode;
  secondaryMain?: ReactNode;
  tertiaryMain?: ReactNode;
  catalogMain?: ReactNode;
  clickMoment: (a: any) => void;
  catalogHandlerCallback: (a: any) => void;
  clickProfileAvatar: (a: any) => void;
  profileAvatar?: string;
  hasANewMessage: boolean;
  onChangeInputSearch: (a: string) => void;
  valueInputSearch: string;
  favoritesClickHandler: (a: string) => void;
  momentsList: MomentsDetails[];
}

const Desk: React.FC<DeskProps> = ({
  hasANewMessage,
  onChangeInputSearch,
  profileAvatar,
  valueInputSearch,
  principalMain,
  secondaryMain,
  tertiaryMain,
  catalogMain,
  clickMoment,
  catalogHandlerCallback,
  clickProfileAvatar,
  favoritesClickHandler,
  momentsList,
}) => {
  const [isPrincipalMainVisible, setIsPrincipalMainVisible] = useState(false);
  const [isSecondaryMainVisible, setIsSecondaryMainVisible] = useState(false);
  const [isTertiaryMainVisible, setIsTertiaryMainVisible] = useState(false);
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [filterType, setFilterType] = useState<"all" | "vip" | "non-vip">(
    "all"
  );

  const { setPrincipalScreen, setSecondaryScreen, setTertiaryScreen } =
    useDeskNavigation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [isPrincipalMainVisible, isSecondaryMainVisible, isTertiaryMainVisible]);

  useEffect(() => {
    if (principalMain) {
      setIsPrincipalMainVisible(true);
    } else {
      setIsPrincipalMainVisible(false);
    }
  }, [principalMain]);

  useEffect(() => {
    if (secondaryMain) {
      setIsSecondaryMainVisible(true);
    } else {
      setIsSecondaryMainVisible(false);
    }
  }, [secondaryMain]);

  useEffect(() => {
    if (tertiaryMain) {
      setIsTertiaryMainVisible(true);
    } else {
      setIsTertiaryMainVisible(false);
    }
  }, [tertiaryMain]);

  const openChatListHandler = () => {
    setSecondaryScreen("");
    setTertiaryScreen("");
    setPrincipalScreen("/chat-list");
  };

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
    switch (section) {
      case "button1":
        setFilterType("all");
        break;
      case "button2":
        setFilterType("vip");
        break;
      case "button3":
        setFilterType("non-vip");
        break;
      default:
        setFilterType("all");
    }
  };

  return (
    <div id="desk-wrapper">
      <HeaderHomePage
        valueInputSearch={valueInputSearch}
        hasANewMessage={hasANewMessage}
        onChangeInputSearch={onChangeInputSearch}
        profileAvatar={profileAvatar}
        headerIcon="roses-logo"
        title="Roses™️"
        clickProfileAvatar={clickProfileAvatar}
        clickChatList={openChatListHandler}
        favoritesClick={favoritesClickHandler}
        className={
          isPrincipalMainVisible ||
          isSecondaryMainVisible ||
          isTertiaryMainVisible
            ? "hidden-component"
            : ""
        }
      />
      <main
        className={`main-page-desk ${
          isPrincipalMainVisible || isSecondaryMainVisible
            ? `space-without-header ${
                isTertiaryMainVisible ? "grid-template-changed" : ""
              }`
            : ""
        }`}
      >
        <div
          className={`catalog-main ${
            isSecondaryMainVisible &&
            isTertiaryMainVisible &&
            isPrincipalMainVisible
              ? "hide-catalog-for-gallery"
              : isPrincipalMainVisible && !isSecondaryMainVisible
              ? "hide-catalog"
              : isSecondaryMainVisible && isPrincipalMainVisible
              ? "secondary-hide-catalog"
              : isSecondaryMainVisible && !isPrincipalMainVisible
              ? "only-secondary-main-is-show"
              : "show-catalog"
          }`}
        >
          <div className="discover-nav-bar">
            {/* <Moments
              momentsImages={momentsList.map((moment, index) => ({
                img: moment.img,
                userId: `user${index}`,
                wasSeen: index < 6,
              }))}
              clickMoment={clickMoment}

            /> */}
            <Moments
              momentsImages={stories.map((moment, index) => ({
                img: moment.url,
                userId: `user${index}`,
                wasSeen: index < 6,
              }))}
              clickMoment={clickMoment}
              // className="catalog-desk margin-bottom-8"
            />
            <ButtonNavigation
              setActiveSection={handleSectionChange}
              activeSection={activeSection}
              buttonCount={5}
              buttonLabels={[
                "All",
                // "Notifications",
                // "Top Earners",
                "Vip", //TEMPORARY - DEVELOP PURPOSE
                "Non-Vip", //TEMPORARY  - DEVELOP PURPOSE
                "New Creators",
                "Online",
              ]}
            />
          </div>

          {catalogMain || (
            <>
              <CardsCatalog
                catalogHandlerCallback={catalogHandlerCallback}
                className="catalog-component-main padding-hor-24"
                filterType={filterType}
              />
              {/* <ModalDefault isOpen={true} classNameChildren="aaa">
                <h1>Test</h1>
              </ModalDefault> */}
            </>
          )}
        </div>

        <div
          className={`principal-main ${
            isPrincipalMainVisible && !isSecondaryMainVisible
              ? "show-principal-main"
              : isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                !isTertiaryMainVisible
              ? "show-principal-main show-principal-and-secondary-main"
              : isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                isTertiaryMainVisible
              ? "show-principal-main hide-principal-main-mobile"
              : "hide-principal-main"
          }`}
        >
          {principalMain}
        </div>

        <div
          className={`secondary-main ${
            isSecondaryMainVisible && !isTertiaryMainVisible
              ? "show-secondary-main"
              : isSecondaryMainVisible &&
                isTertiaryMainVisible &&
                isPrincipalMainVisible
              ? "show-secondary-main show-secondary-and-tertiary-main"
              : isSecondaryMainVisible &&
                isTertiaryMainVisible &&
                !isPrincipalMainVisible
              ? "show-secondary-main only-secondary-and-tertiary-main-show"
              : "hide-secondary-main"
          }`}
        >
          {secondaryMain}
        </div>

        <div
          className={`tertiary-main ${
            isTertiaryMainVisible &&
            isSecondaryMainVisible &&
            isPrincipalMainVisible
              ? "show-tertiary-main"
              : !isPrincipalMainVisible &&
                isSecondaryMainVisible &&
                isTertiaryMainVisible
              ? "show-all-main"
              : "hide-tertiary-main"
          }`}
        >
          {tertiaryMain}
        </div>
      </main>

      {/* <div className="footer-desk padding-hor-8">{bottomChildren}</div> */}
    </div>
  );
};

export default Desk;
