import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import "./styles.scss";

interface IRating {
  rating: number;
  isVip: boolean;
}

const Rating: React.FC<IRating> = ({ rating, isVip }: IRating) => {
  const stars = Array.from({ length: 5 }, (_, index) => (
    <FontAwesomeIcon
      key={index}
      icon={index < rating ? faStarSolid : faStarRegular}
      className={index < rating ? "star filled" : "star"}
    />
  ));
  return <div className={`rating-stars ${isVip ? "vip" : ""}`}>{stars}</div>;
};

export default Rating;
