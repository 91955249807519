export const textCapitalizeArray = (text: string[] | undefined) => {
  if (text) {
    return text.map(
      (word) =>
        word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()
    );
  } else {
    return undefined;
  }
};

export const textCapitalize = (text: string | undefined):string=>  {
  if (text) {
    let words = text?.replaceAll("_", " ").split(" ");

    let capWords = words.map(
      (word) =>
        word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()
    );
    return capWords.join(" ");
  } else {
		return ""
	}
};
