import { servicesApi } from "../fetchApi/services-api";

const postAddReferralCode = async (token: string, referralCode: string) => {
  const apiRoute = `${servicesApi(
    "creator_applications"
  )}/referrals/${referralCode}`;

  const Authorization = `Bearer ${token}`;

  try {
    const response = await fetch(apiRoute, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return { res: data, status: response.status };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
		return { res: error, status: null };
  }
};

export default postAddReferralCode;
