import React from "react";
import "./styles.scss";
import LockGray from "../../assets/icons/lock-icon-gray.svg";
import Button from "../Buttons/Button";

interface ChatLockedProps {
  onClickButton: () => void;
}

const ChatLocked: React.FC<ChatLockedProps> = ({
  onClickButton,
}: ChatLockedProps) => {
  const [selected, setSelected] = React.useState<string>("oneRoses");

  return (
    <div className="locked-container margin-16">
      <div className="superior-container">
        <div className="locked-title-container">
          <img src={LockGray} alt="Lock Icon" className="lock-icon" />
          <h1 className="locked-title margin-top-16">CHAT LOCKED</h1>
          <h2 className="locked-subtitle">Unlock chat to begin interaction</h2>
        </div>
        <div className="unlock-container margin-bottom-16">
          <div
            style={{
              backgroundColor:
                selected === "oneRoses" ? "#333" : "var(--midnight)",
            }}
            className="unlock-inner"
          >
            <p className="unlock-text">Unlock Chat Connect</p>
            <div className="unlock-desc">
              <p className="roses-number">1 Rose</p>
              <input
                type="radio"
                className="custom-radio"
                value="oneRoses"
                checked={selected === "oneRoses"}
                onChange={() => setSelected("oneRoses")}
              />
            </div>
          </div>
        </div>
        <div className="unlock-container secondary">
          <div
            style={{
              backgroundColor:
                selected === "twentyFiveRoses" ? "#333" : "var(--midnight)",
            }}
            className="unlock-inner"
          >
            <p className="unlock-text">Schedule Voice Connect</p>
            <div className="unlock-desc">
              <p className="roses-number">25 Roses</p>
              <input
                type="radio"
                className="custom-radio"
                value="twentyFiveRoses"
                checked={selected === "twentyFiveRoses"}
                onChange={() => setSelected("twentyFiveRoses")}
              />
            </div>
          </div>
        </div>{" "}
        <Button
          buttonStyle="primary"
          onClick={onClickButton}
          className="margin-bottom-16"
        >
          SEND REQUEST
        </Button>
      </div>
    </div>
  );
};

export default ChatLocked;
