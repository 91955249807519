import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "../../../components/PageTitles";
import ButtonNavigation from "../../../components/Buttons/ButtonNavigation";
import InputText from "../../../components/Inputs/InputText";
import chevronRightIcon from "../../../assets/icons/navigationIcons/bx-chevron-right.svg";
import { useWebSocket } from "../../../contexts/webSocketContext";
import { useNavigate } from "react-router-dom";
import { useDeskNavigation } from "../../../hooks/useDeskNavigation";
import ListItemSecondary from "../../../components/Lists/ListItemSecondary";
import getListUserChats from "../../../api/chat/getListUserChats";
import { useUserInfo } from "../../../hooks/userInfo";
import { Chat } from "../../../types/chatInfo";
import profileAvatar from "../../../assets/icons/profile-icon-gray.svg";

const ChatListPage: React.FC = () => {
  const { getChats } = useWebSocket();
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [chats, setChats] = useState<Chat[]>([]);
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();
  const { addChatListener, removeListener, getMessages, getChat } =
    useWebSocket();
  const [newMessagesCount, setNewMessagesCount] = useState(0);

  const { setTertiaryScreen, setSecondaryScreen, setPrincipalScreen } =
    useDeskNavigation();

  useEffect(() => {
    const handleNewMessage = (newMessage: any) => {
      if (newMessage.eventType === "NEW_MESSAGE") {
        // setChats((prevMessages) => [...prevMessages, newMessage.payload]);
        setNewMessagesCount((prev) =>
          !newMessage.payload.isRead ? prev + 1 : 0
        );
        console.log(!newMessage.payload.isRead);
      }
    };

    addChatListener(handleNewMessage);

    return () => {
      removeListener("events", handleNewMessage);
    };
  }, [addChatListener, removeListener]);

  const messageInChatRequest = async () => {
    const response = await getListUserChats(userInfo.access_token);

    switch (response.status) {
      case 200:
        // if(response.res.filter((chat:Chat) => chat._count.messages !== 0).length > 0){
        // 	setHasANewMessage(true)
        // }
        break;

      case 401:
        navigate("/app-login");
        break;

      default:
        navigate("/app-login");
        break;
    }
  };

  const getListChats = useCallback(async () => {
    await getChats({}, (res) => {
      setChats(res);
    });
  }, [getChats]);

  useEffect(() => {
    messageInChatRequest();
    getListChats();
  }, [getChats, getListChats]);

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  const handleChat = (chatId: string, userId: string) => {
    setSecondaryScreen("/personal-chat", { chatId, userId });
  };

  const messagePreview = (message: string) => {
    return message?.length > 50 ? `${message.substring(0, 40)}...` : message;
  };

  return (
    <div className="chat-list margin-hor-24">
      <div className="top-container">
        <PageTitle
          title={"Messages"}
          subtitle={"Conversation"}
          onBackClick={() => {
            setPrincipalScreen("");
            setTertiaryScreen("");
            setSecondaryScreen("");
          }}
        />
        <InputText
          value={"Search"}
          placeholder="Search"
          onChangeInput={() => {}}
          searchInput
        />
        <ButtonNavigation
          setActiveSection={handleSectionChange}
          activeSection={activeSection}
          buttonCount={2}
          buttonLabels={["Messages", "Notifications"]}
          className="margin-vert-24"
        />
      </div>
      {activeSection === "button1" && (
        <>
          <div className="input-container"></div>
          {chats.map((chat) => (
            <ListItemSecondary
              key={chat.chatId}
              clickAction={() =>
                handleChat(chat.chatId, chat.chatParticipants[0].user.userId)
              }
              showLabel
              labelContent={chat.chatParticipants[0]?.user.displayname}
              showIconLeft
              iconLeftContent={
                chat.chatParticipants[0]?.user.profile?.photos[0]?.url
                  ? chat.chatParticipants[0]?.user.profile?.photos[0]?.url
                  : profileAvatar
              }
              showIconBorderLeft
              showDescription
              descriptionContent={
                messagePreview(chat.messages[0]?.content) || ""
              }
              showIconRight
              iconRightContent={chevronRightIcon}
              showSeparator
              nodeSectionEndTextContainer={
                chat._count.messages > 0 && (
                  <div className="notification-count">
                    <p>{chat._count.messages + newMessagesCount}</p>
                  </div>
                )
              }
            />
          ))}
        </>
      )}

      {activeSection === "button2" && <></>}
    </div>
  );
};

export default ChatListPage;
