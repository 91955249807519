import React, { useState } from "react";
   
import PageTitle from "../../components/PageTitles";
import ListItem from "../../components/Lists/ListItem";
import ButtonNavigation from "../../components/Buttons/ButtonNavigation";
import InputText from "../../components/Inputs/InputText";
import disclaimerIcon from "../../assets/icons/union-gray.svg";
import DashboardGrid from "../../components/Wallet/ActivityDashboard/ActivityDashboardGrid";
import AutoRecharge from "../../components/Wallet/AutoRecharge";
import RosesDashboard from "../../components/Wallet/RosesDashboard";
import Button from "../../components/Buttons/Button";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";

const UserSettingsWalletPage: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [photosAlbum, setPhotosAlbum] = useState<(File | null)[] | []>();

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  return (
    <div className="user-settings-wallet">
      <PageTitle
        title={"My Wallet"}
        subtitle={"Manage Payment"}
        onBackClick={() => setSecondaryScreen("")}
      />
      <ButtonNavigation
        setActiveSection={handleSectionChange}
        activeSection={activeSection}
        buttonCount={3}
        buttonLabels={["Overview", "Activity", "Payout"]}
					className="margin-vert-24"
      />

      {activeSection === "button1" && (
        <>
          <RosesDashboard title={"3,420"} showAddButton={true} />
          <AutoRecharge />
          <div className="list-container">
            <ListItem
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Visa"
              showDescription={true}
              descriptionContent="Credit Card ***5678"
              showRadioRight={true}
              showSeparator={true}
            />
            <ListItem
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Master Card"
              showDescription={true}
              descriptionContent="Debit Card ***2109"
              showRadioRight={true}
            />
          </div>
          <Button
            buttonStyle="tertiary"
            onClick={() => {}}
            children={"Add Card"}
          />
        </>
      )}

      {activeSection === "button2" && (
        <>
          <DashboardGrid />
          <InputText
            value={"Search"}
            placeholder="Search"
            onChangeInput={() => {}}
            searchInput
          />
          <div className="list-container">
            <ListItem
              showLabel={true}
              labelContent="Buy Credit"
              showDescription={true}
              descriptionContent="Feb. 21, 2024"
              showValue={true}
              valueContent={"-$500"}
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Buy Credit"
              showDescription={true}
              descriptionContent="Feb. 21, 2024"
              showValue={true}
              valueContent={"-$500"}
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Buy Credit"
              showDescription={true}
              descriptionContent="Feb. 21, 2024"
              showValue={true}
              valueContent={"-$500"}
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Buy Credit"
              showDescription={true}
              descriptionContent="Feb. 21, 2024"
              showValue={true}
              valueContent={"-$500"}
              showSeparator={true}
            />
            <ListItem
              showLabel={true}
              labelContent="Buy Credit"
              showDescription={true}
              descriptionContent="Feb. 21, 2024"
              showValue={true}
              valueContent={"-$500"}
              showSeparator={true}
            />
          </div>
        </>
      )}

      {activeSection === "button3" && (
        <>
          <RosesDashboard title={"3,420"} showRedeemButton={true} />
          <div className="list-container">
            <ListItem
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Bank of America"
              showDescription={true}
              descriptionContent="Checkins ***0814"
              showRadioRight={true}
              showSeparator={true}
            />
            <ListItem
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Chase"
              showDescription={true}
              descriptionContent="Checkins ***3275"
              showRadioRight={true}
            />
          </div>
          <Button
            buttonStyle="tertiary"
            onClick={() => {}}
            children={"Connect Account"}
          />
        </>
      )}
    </div>
  );
};

export default UserSettingsWalletPage;
