import React from "react";
import "./styles.scss";

interface ButtonNavigationProps {
  setActiveSection: (section: string) => void;
  activeSection: string;
  buttonCount: number;
  buttonLabels: string[];
  className?: string;
}

const ButtonNavigation: React.FC<ButtonNavigationProps> = ({
  setActiveSection,
  activeSection,
  buttonCount,
  buttonLabels,
  className,
}) => {
  const handleClick = (buttonIndex: number) => {
    setActiveSection(`button${buttonIndex + 1}`);
  };

  return (
    <div className={`action-button-group  ${className}`}>
      {Array.from({ length: buttonCount }, (_, index) => (
        <button
          key={index}
          className={`action-button ${
            activeSection === `button${index + 1}` ? "active" : ""
          }`}
          onClick={() => handleClick(index)}
        >
          <p
            className={`no-margin ${
              activeSection === `button${index + 1}` ? "active" : ""
            }`}
          >
            {buttonLabels[index]}
          </p>
        </button>
      ))}
    </div>
  );
};

export default ButtonNavigation;
