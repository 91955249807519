import { useEffect, useState } from "react";
import Header from "../../components/Headers/Header";
   
import "./styles.scss";
import LockIcon from "../../assets/icons/lock-icon-gray.svg";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
 
import { ClipLoader } from "react-spinners";
import postUserAuth from "../../api/auth/postLogin";
import Toast from "../../components/Toast";
import { useNavigate } from "react-router-dom";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import * as jwtDecode from "jwt-decode";
import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";
import { IInfoToken } from "../../types/infoToken";

const PasswordSignInPage: React.FC = () => {
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [toastErrorMsg, setToastErrorMsg] = useState("");
  const { setComponent } = useLoginSignUpNavigation();
  const { userInfo, setUserInfo } = useUserInfo();

  const navigate = useNavigate();

  useEffect(() => {
    const creatorStatus = async () => {
      const response = await getCreatorStatus(userInfo.access_token);
      if (response?.status === 200) {
        setUserInfo("referral_code", response?.res.referralCode);
        setUserInfo("creator_application_status", response.res.status);
        setUserInfo(
          "creator_application_id",
          response?.res.creatorApplicationId
        );
      }
    };

    creatorStatus();
  }, []);

  const loginSubmit = async () => {
    setIsLoading(true);

    // const prepareData = {
    //   phone: userInfo.user_phone.split("+")[1],
    //   password: userPassword,
    // };
    const prepareData = {
      email: userInfo.user_phone.split("+")[1],
      password: userPassword,
    };

    let responseAuth = await postUserAuth(prepareData);

    switch (responseAuth.status) {
      case 200:
        let response = await responseAuth.res
        let access_token = response.access_token;
        const userInfoToken = (await jwtDecode.jwtDecode(
          access_token
        )) as IInfoToken;

        setUserInfo("access_token", access_token);
        setUserInfo("display_name", userInfoToken?.validUser.displayname);
        setUserInfo("account_type", userInfoToken?.validUser.role);
        setUserInfo("user_phone", userInfoToken?.validUser.phone);
        setUserInfo("user_email", userInfoToken?.validUser.email);
        setUserInfo("user_id", userInfoToken?.validUser.userId);

        const responseStatus = await getCreatorStatus(access_token);

        if (responseStatus?.status === 200) {
          setUserInfo("referral_code", responseStatus?.res.referralCode);
          setUserInfo("creator_application_status", responseStatus?.res.status);
          setUserInfo(
            "creator_application_id",
            responseStatus?.res.creatorApplicationId
          );
        }

        if (
          responseStatus?.res.status === "APPROVED" &&
          userInfoToken?.validUser.role.toLocaleLowerCase() === "consumer"
        ) {
          setComponent("/welcome-user");
        } else {
          navigate("/app");
        }

        break;
      case 401:
        setHasError(true);
        setIsWarning(true);
        setToastErrorMsg("Check your credentials and, please,  try again");

        setTimeout(() => {
          setIsWarning(false);
          setHasError(false);
        }, 4000);
        break;
    }

    setIsLoading(false);
  };

  const cancelSubmit = () => {
    navigate("/");
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      loginSubmit();
    }
  };

  return (
       <div className="password-sign-in-page">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => setComponent("/create-number-verify")}
          title={
            <>
              Enter your
              <br />
              password
            </>
          }
          subTitle={
            <>
              Your password should be at least <br /> 8 characters long
            </>
          }
        />
        <InputText
          inputType="password"
          value={userPassword}
          placeholder="password"
          onChangeInput={(e) => setUserPassword(e)}
          onKeyDown={onKeyDown}
          isWarning={isWarning}
        />
        <SubText icon={LockIcon} altIcon="clock icon">
          Never share your credentials with anyone. Forgot your password?
          <span
            className="reset-password padding-left-4"
            onClick={() => setComponent("/reset-password")}
          >
            Reset Password
          </span>
        </SubText>
      </div>

       
        <SubText>
          Don't have an account?{" "}
          <span
            className="anchor-link"
            onClick={() => setComponent("/create-account")}
          >
            Create an account.
          </span>
        </SubText>
        <Button
          disabled={userPassword.length < 8 || isLoading}
          buttonStyle="primary"
          onClick={loginSubmit}
        >
          {isLoading ? (
            <ClipLoader color={"#FFF"} loading={true} size={15} />
          ) : (
            "Login"
          )}
        </Button>
        <Button buttonStyle="quaternary" onClick={cancelSubmit}>
          Cancel
        </Button>
        
      <Toast
        type="error"
        isVisible={hasError}
        setIsVisible={setHasError}
        title="Error"
        description={toastErrorMsg}
      />
    </div>
  );
};

export default PasswordSignInPage;
