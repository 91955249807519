import React, { useState } from "react";
import "./styles.scss";

interface RangeProps {
  min: number;
  max: number;
  rangeCallback: (value: string) => void;
	step?:number;
}

const Range: React.FC<RangeProps> = (props) => {
  const { max, min, rangeCallback,step } = props;
  const [rangeValue, setRangeValue] = useState("");

  const onChangeRange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRangeValue(event.target.value);
    rangeCallback(event.target.value);
  };

  return (
    <div id="range-default">
      <input
        type="range"
        id="range_val"
        min={min}
        max={max}
        value={rangeValue}
        onChange={onChangeRange}
				step={step}
      />
    </div>
  );
};

export default Range;
