import fetchApi from "../fetchApi";

const postCompleteCreatorAccount = async (token: string) => {
  return await fetchApi({
    service: "creator_applications",
    endPoint: "/complete-creator-account",
    method: "POST",
    token,
  });
};

export default postCompleteCreatorAccount;
