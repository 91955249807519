import fetchApi from "../fetchApi";

const getListUserChats = async (token: string) => {
  return await fetchApi({
    service: "chat",
    endPoint: `/list`,
    method: "GET",
    token,
  });
};

export default getListUserChats;
