import { useState } from "react";
import Header from "../../components/Headers/Header";
   
import "./styles.scss";
import Button from "../../components/Buttons/Button";
import Toast from "../../components/Toast";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";

const AllowLocationPage: React.FC = () => {
  const [error, setError] = useState("");
  const [hasError, setHasError] = useState(false);
  const { setPrincipalScreen } = useDeskNavigation();
  const { setComponent } = useLoginSignUpNavigation();

  const errorHandler = (error: GeolocationPositionError) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setError("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        setError("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        setError("The request to get user location timed out.");
        break;
      default:
        setError("An unknown error occurred.");
        break;
    }
  };

  const enableLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const long = position.coords.longitude;

          setComponent("/allow-notification");
        },
        (error) => {
          setHasError(true);
          errorHandler(error);

          setTimeout(() => {
            setHasError(true);
          }, 3000);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      setHasError(true);
      setTimeout(() => {
        setHasError(true);
      }, 3000);
    }
  };

  const cancelEnableLocation = () => {
    setComponent("/allow-notification");
  };

  return (
       <div className="allow-location">
      <Header
        headerIcon="pin-icon"
        backButtonClick={() => setPrincipalScreen("/recovery-email")}
        title={
          <>
            Enable <br />
            location service
          </>
        }
        subTitle={"We use your location to show potential matches in your area"}
      />


        <Button buttonStyle="secondary" onClick={enableLocation}>
          Enable Location
        </Button>
        <Button buttonStyle="tertiary" onClick={cancelEnableLocation}>
          Not Now
        </Button>

      <Toast
        type="error"
        title="Error"
        description={error}
        isVisible={hasError}
        setIsVisible={setHasError}
      />
    </div>
  );
};

export default AllowLocationPage;
