import React, { ReactNode } from "react";
import { AuthProvider } from "./auth";
import { PwaProvider } from "./pwa";
import { DeskNavigationProvider } from "./useDeskNavigation";
import { LoginSignUpProvider } from "./useLoginSignUpNavigation";
import { UserInfoProvider } from "./userInfo";
import { ToggleChatPageProvider } from "../contexts/toggleChatOpen";
import { FilesProvider } from "../contexts/documentContext";
import { ModalProvider } from "./useModal";

interface AppProviderProps {
  children: ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <PwaProvider>
      <AuthProvider>
        <DeskNavigationProvider>
          <UserInfoProvider>
            <LoginSignUpProvider>
              <FilesProvider>
                <ModalProvider>
                  <ToggleChatPageProvider>{children}</ToggleChatPageProvider>
                </ModalProvider>
              </FilesProvider>
            </LoginSignUpProvider>
          </UserInfoProvider>
        </DeskNavigationProvider>
      </AuthProvider>
    </PwaProvider>
  );
};

export default AppProvider;
