import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import PageTitle from "../../components/PageTitles";
import "./styles.scss";
import ChatLocked from "../../components/ChatLocked";
import ChatMessage from "../../components/ChatMessage";
import { useWebSocket } from "../../contexts/webSocketContext";
import { format } from "date-fns";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { ICatalogResponse } from "../../types/catalog";
import CreateChatContact from "../../api/postCreateChatContact";
import { useUserInfo } from "../../hooks/userInfo";
import ChatTextarea from "../../components/TextAreas/ChatTextArea";
import { ToggleChatOpenContext } from "../../contexts/toggleChatOpen";
import { Chat, Message } from "../../types/chatInfo";
import getPublicDetails from "../../api/publicDetails/getProfileDetails";
import PinkButton from "../../components/Buttons/PinkButton";
import { useModal } from "../../hooks/useModal";
import playIcon from "../../assets/icons/play-circle-white.svg";
import loadingPinkIcon from "../../assets/icons/sphere-loading-pink.svg";
import CloseIconGray from "../../assets/icons/closeIcons/close-icon-gray.svg";
import SendAudioChat from "../../components/SendAudioChat";
import postUploadMedia from "../../api/chat/postUploadMedia";
import postUnlockChatContent from "../../api/chat/postUnlockContent";
import CloseIconPeach from "../../assets/icons/closeIcons/close-icon-peach.svg";
import Button from "../../components/Buttons/Button";
import rosesLogoPink from "../../assets/icons/logos/logo-fill-pink.svg";

type IMessagePayload = {
  eventType: string;
  payload: Message;
};

const ChatPage: React.FC = () => {
  const {
    addChatListener,
    removeListener,
    sendMessage,
    getMessages,
    getChat,
    updateMessageStatus,
  } = useWebSocket();
  const { setSecondaryScreen, setTertiaryScreen, params } = useDeskNavigation();
  const { setOpenUseModal, setModalContent } = useModal();
  const { chatId, userId, isLocked } = params;
  const { userInfo } = useUserInfo();
  const { setIsChatPageOpen } = useContext(ToggleChatOpenContext);
  const inputChatRef = useRef<HTMLInputElement>(null);
  const [chatLocked, setChatLocked] = useState<boolean>(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [chat, setChat] = useState<Chat>();
  const [hasAMomentToSee, setHasAMomentToSee] = useState(false);
  const [isContactOnline, setIsContactOnline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [producerDetails, setProducerDetails] = useState<ICatalogResponse>({
    displayname: "",
    isFavorite: false,
    isVip: false,
    profile: {
      age: 0,
      gender: "",
      height: 0,
      location: "",
      maritalStatus: "",
      occupation: "",
      photos: [],
      profileDetails: [],
      rating: 0,
      weight: 0,
    },
    userId: "",
  });
  const [takeMessages, setTakeMessages] = useState(10);
  const [unreadMessageIds, setUnreadMessageIds] = useState<string[]>([]);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const videoMessageRef = useRef<HTMLVideoElement>(null);
  const imagePlayerMessageRef = useRef<HTMLImageElement>(null);
  const videoPreviewSendRef = useRef<HTMLVideoElement>(null);
  const imagePlayerPreviewSendRef = useRef<HTMLImageElement>(null);
  const messagesContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const getChatInfo = async () => {
      addChatListener(handleNewMessage);

      await handleMessages();
      await getInChat();

      return () => {
        removeListener("NEW_MESSAGE", handleNewMessage);
      };
    };

    const isChatLocked = isLocked === "false" ? false : true;
    setChatLocked(!isChatLocked);

    if (isChatLocked) {
      handleCreateChatContact();
    }

    producerDetail();
    getChatInfo();
  }, [addChatListener, isLocked, removeListener, userId, chatId]);

  // useEffect(() => {
  //   handleMessages();
  // }, [takeMessages]);

  // useLayoutEffect(() => {
  //   const chatMessages = document.getElementById("chat-messages-container");

  //   const handleScroll = () => {
  //     if (chatMessages) {
  //       const { scrollTop, scrollHeight, clientHeight } = chatMessages;

  //       if (scrollTop + clientHeight >= scrollHeight - 10) {
  //         setTakeMessages((prev) => prev + 10);
  //       }

  //       if (scrollTop === 0) {
  //         setTakeMessages((prev) => (prev > 10 ? prev - 10 : prev));
  //       }
  //     }
  //   };

  //   console.log(chatMessages);
  //   if (chatMessages) {
  //     console.log("entrou no if");
  //     chatMessages.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (chatMessages) {
  //       chatMessages.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, [messagesContainer, takeMessages]);

  const markMessagesAsRead = (messageIds: string[]) => {
    updateMessageStatus(messageIds, (response) => {
      console.log(response);
    });
  };

  useEffect(() => {
    if (unreadMessageIds.length > 0) {
      const messagesIds = new Set(unreadMessageIds);

      markMessagesAsRead(Array.from(messagesIds));
    }
  }, [unreadMessageIds, updateMessageStatus]);

  const addUnreadMessage = (newMessageId: string) => {
    setUnreadMessageIds((prevIds) => [...prevIds, newMessageId]);
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      const chatMessages = document.getElementById("chat-messages-container");
      const fixedComponentHeight = 200;

      if (chatMessages) {
        console.log("achou a div");
        chatMessages.scrollTop =
          chatMessages.scrollHeight + fixedComponentHeight;
      }
    }, 0);
  };
  const formattedTime = (date: Date) => format(date, "HH:mm");

  const backPageHandler = () => {
    setSecondaryScreen("");
    setTertiaryScreen("");
    setIsChatPageOpen(false);
  };

  // const participant = useMemo(() => {
  //   if (chat?.chatParticipants) {
  //     return chat?.chatParticipants?.find(
  //       (participant) => participant.user.userId !== userInfo.user_id
  //     )?.user;
  //   }
  // }, [chat]);

  const getInChat = async () => {
    const response = await getChat(chatId, setChat);
  };

  const producerDetail = async () => {
    if (userInfo.access_token) {
      const response = await getPublicDetails(userInfo.access_token, userId);

      switch (response?.status) {
        case 200:
          setProducerDetails(response.res);

          break;
        default:
          // setPrincipalScreen("");
          break;
      }
    }
  };

  // const handleMessages = async () => {
  //   const messageParams = {
  //     chatId,
  //     take: takeMessages,
  //   };

  //   await getMessages(messageParams, (messages: Message[]) => {
  //     const chatMessages = messages.filter((msg) => msg.chatId === chatId);
  //     setMessages(chatMessages);

  //     const interestMessagesIds = chatMessages
  //       .filter(
  //         (msg) =>
  //           msg?.userId === userId &&
  //           msg?.expiresAt === null &&
  //           msg?.isTemp &&
  //           !msg?.isSystemMessage &&
  //           !msg?.messageSettings?.isPaid &&
  //           !msg?.isRead
  //       )
  //       .map((msg) => msg.messageId);

  //     interestMessagesIds.forEach((id) => {
  //       addUnreadMessage(id);
  //     });
  //   });
  // };
  const handleMessages = async () => {
    const messageParams = {
      chatId,
      take: takeMessages,
    };

    scrollToBottom();
    await getMessages(messageParams, (messages: Message[]) => {
      setMessages((prevMessages) => {
        const newMessages = messages.filter((msg) => msg.chatId === chatId);
        // return [...prevMessages, ...newMessages];
        return [...newMessages];
      });

      const interestMessagesIds = messages
        .filter((msg) => !msg.isRead && msg.userId === userId)
        .map((msg) => msg.messageId);

      if (interestMessagesIds.length > 0) {
        interestMessagesIds.forEach(addUnreadMessage);
      }
    });
  };

  const handleNewMessage = (message: IMessagePayload) => {
    if (message.eventType === "NEW_MESSAGE") {
      setMessages((prevMessages) => [...prevMessages, message.payload]);
    }
    scrollToBottom();
  };

  const handleSendMessage = async (text: string) => {
    setIsLoading(true);
    try {
      await new Promise<void>((resolve, reject) => {
        sendMessage({ chatId, content: text }, (message) => {
          setMessages((prevMessages) => [...prevMessages, message]);
          resolve();
        });
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      inputChatRef.current?.focus();
    }
    // scrollToBottom();
    setIsLoading(false);
  };

  const openMediaLibrary = () => {
    setTertiaryScreen("/media-library");
  };

  const handleCreateChatContact = async () => {
    setChatLocked(false);
    const chatInfo = await CreateChatContact().postCreateChatContact(
      userId,
      userInfo.access_token
    );

    setIsContactOnline(chatInfo.res.chatParticipants[0].user.isOnline);
  };

  const prepareSendFiles = (files: File[]) => {
    return files.map((file) => {
      return new Promise<string | ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          if (reader.result) {
            resolve(reader.result as string);
          } else {
            reject(new Error("Failed to read file"));
          }
        };
        reader.onerror = () => reject(new Error("File reading error"));

        reader.readAsDataURL(file);
      });
    });
  };

  const unlockContent = async (messageId: string) => {
    setModalContent(
      <div className="loading-send-file">
        <img src={loadingPinkIcon} alt="loading pink icon" />
      </div>
    );

    const response = await postUnlockChatContent(
      userInfo.access_token,
      messageId
    );

    setOpenUseModal(false);
    setModalContent(null);
		await handleMessages()
    scrollToBottom();
  };

  const uploadFileCallBack = async (file: File[], content: string) => {
    setModalContent(
      <div className="loading-send-file">
        <img src={loadingPinkIcon} alt="loading pink icon" />
      </div>
    );

    const prepareData = {
      chatId,
      content,
      price: 55,
      paidContent: true,
      files: file,
    };
    const response = await postUploadMedia(userInfo.access_token, prepareData);

    switch (response.status) {
      case 201:
        scrollToBottom();
        break;

      default:
        break;
    }

    setOpenUseModal(false);
    setModalContent(null);
		await handleMessages()
  };

  const contentModalPreviewPhoto =  (files: File[]) => {

    const imagePromises = prepareSendFiles(files);

    Promise.all(imagePromises).then((images) => {
      const imageElements = images.map((imageSrc, index) => (
        <div key={index} className="preview-file-container">
          <img
            src={imageSrc as string}
            alt={`Preview ${index}`}
            className="file-modal-content"
          />
        </div>
      ));

      setModalContent(
        <div className="modal-preview-file padding-24">
          <div className="header-modal-preview">
            <img
              src={CloseIconGray}
              alt="close icon"
              onClick={() => setOpenUseModal(false)}
            />
          </div>
          {imageElements}
          <ChatTextarea
            autoFocus
            isButtonSendDisabled={isLoading}
            hasEmptyMessage
            onSendMessage={(e) => uploadFileCallBack(files, e)}
          />
        </div>
      );
    });

    setOpenUseModal(true);

  };

  const contentModalPreviewVideo =  (files: File[]) => {

    const playPauseHandlerPreview = () => {
      if (videoPreviewSendRef?.current) {
        const isPaused = videoPreviewSendRef.current.paused;

        if (imagePlayerPreviewSendRef.current) {
          imagePlayerPreviewSendRef.current.style.display = isPaused
            ? "none"
            : "block";
        }

        if (isPaused) {
          videoPreviewSendRef.current.play();
        } else {
          videoPreviewSendRef.current.pause();
        }
      }
    };
    const videoPromises = prepareSendFiles(files);
    Promise.all(videoPromises)
      .then((videoSources) => {
        const videoElements = videoSources.map((videoSrc, index) => (
          <div key={index} className="preview-file-container video-wrapper">
            <video
              onClick={playPauseHandlerPreview}
              ref={videoPreviewSendRef}
              className="file-modal-content"
            >
              <source src={videoSrc as string} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ));

        setModalContent(
          <div className="modal-preview-file">
            <img
              ref={imagePlayerPreviewSendRef}
              src={playIcon}
              alt="play icon"
              className="play-icon"
            />
            <div className="header-modal-preview">
              <img
                src={CloseIconGray}
                alt="close icon"
                onClick={() => setOpenUseModal(false)}
              />
            </div>

            {videoElements}
            <ChatTextarea
              autoFocus
              hasEmptyMessage
              isButtonSendDisabled={isLoading}
              onSendMessage={(e) => {
                uploadFileCallBack(files, e);
              }}
            />
          </div>
        );
      })
      .catch((error) => {
        console.error("Error loading videos:", error);
      });

    setOpenUseModal(true);

  };

  const contentModalPreviewPack = (files: File[]) => {
    let currentFiles = [...files];

    const removeFile = (indexToRemove: number) => {
      currentFiles = currentFiles.filter((_, index) => index !== indexToRemove);
      updateModalContent();
      if (!currentFiles.length) {
        setOpenUseModal(false);
      }
    };

    const playPauseHandler = () => {
      if (videoMessageRef?.current) {
        const isPaused = videoMessageRef.current.paused;

        if (imagePlayerMessageRef.current) {
          imagePlayerMessageRef.current.style.display = isPaused
            ? "none"
            : "block";
        }

        if (isPaused) {
          videoMessageRef.current.play();
        } else {
          videoMessageRef.current.pause();
        }
      }
    };

    const updateModalContent = () => {
      const packPromises = prepareSendFiles(currentFiles);

      Promise.all(packPromises)
        .then((packSources) => {
          const videos = packSources.filter((file) =>
            file.toString().includes("data:video/")
          );

          const photos = packSources.filter((file) =>
            file.toString().includes("data:image/")
          );

          const packVideos = videos.map((packSrc, index) => (
            <div key={index} className="preview-file-container">
              <img
                src={CloseIconPeach}
                alt="exclude file icon"
                onClick={() => removeFile(index)}
              />
              <img
                ref={imagePlayerMessageRef}
                src={playIcon}
                alt="play icon"
                className="play-icon"
              />
              <video
                controls
                className="file-modal-content"
                onClick={playPauseHandler}
              >
                <source src={packSrc as string} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ));

          const packPhotos = photos.map((packSrc, index) => (
            <div key={index} className="preview-file-container">
              <img
                src={CloseIconPeach}
                alt="exclude file icon"
                onClick={() => removeFile(index)}
              />
              <img
                src={packSrc as string}
                alt={`Preview ${index}`}
                className="file-modal-content"
              />
            </div>
          ));

          setModalContent(
            <div className="modal-preview-file">
              <div className="header-modal-preview">
                <img
                  src={CloseIconGray}
                  alt="close icon"
                  onClick={() => setOpenUseModal(false)}
                />
              </div>
              <div className="scroll-files">
                {packPhotos}
                {packVideos}
                <ChatTextarea
                  autoFocus
                  hasEmptyMessage
                  isButtonSendDisabled={isLoading}
                  onSendMessage={(e) => {
                    uploadFileCallBack(currentFiles, e);
                  }}
                  className="chat-page-pack"
                />
              </div>
            </div>
          );
        })
        .catch((error) => {
          console.error("Error loading videos:", error);
        });
    };

    updateModalContent();
    setOpenUseModal(true);

  };

  const imageMessageModalHandler = (url: string) => {
    console.log("imageMessageModalHandler");
    setModalContent(
      <div className="modal-preview-file">
        <div className="header-modal-preview">
          <img
            src={CloseIconGray}
            alt="close icon"
            onClick={() => setOpenUseModal(false)}
          />
        </div>
        <div className="preview-file-container">
          <img src={url} alt={`preview-image`} className="file-modal-content" />
        </div>
      </div>
    );

    setOpenUseModal(true);
  };

  const videoMessageModalHandler = (url: string) => {
    console.log("videoMessageModalHandler");
    const playPauseHandler = () => {
      if (videoMessageRef?.current) {
        const isPaused = videoMessageRef.current.paused;

        if (imagePlayerMessageRef.current) {
          imagePlayerMessageRef.current.style.display = isPaused
            ? "none"
            : "block";
        }

        if (isPaused) {
          videoMessageRef.current.play();
        } else {
          videoMessageRef.current.pause();
        }
      }
    };

    setModalContent(
      <div className="modal-preview-file">
        <img
          ref={imagePlayerMessageRef}
          src={playIcon}
          alt="play icon"
          className="play-icon"
        />

        <div className="header-modal-preview">
          <img
            src={CloseIconGray}
            alt="close icon"
            onClick={() => setOpenUseModal(false)}
            className="close-icon"
          />
        </div>

        <div className="preview-file-container">
          <video
            className="file-modal-content"
            onClick={playPauseHandler}
            ref={videoMessageRef}
          >
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );

    setOpenUseModal(true);
  };

  const packMessageModalHandler = (
    urlsObj: {
      url: string;
      fileType: string;
      mimetype: string;
    }[]
  ) => {
    console.log("packMessageModalHandler");
    const playPauseHandler = () => {
      if (videoMessageRef?.current) {
        const isPaused = videoMessageRef.current.paused;

        if (imagePlayerMessageRef.current) {
          imagePlayerMessageRef.current.style.display = isPaused
            ? "none"
            : "block";
        }

        if (isPaused) {
          videoMessageRef.current.play();
        } else {
          videoMessageRef.current.pause();
        }
      }
    };

    const packVideos = urlsObj
      .filter((file) => file.fileType === "VIDEO")
      .map((packSrc, index) => (
        <div key={index} className="preview-file-container">
          <img
            ref={imagePlayerMessageRef}
            src={playIcon}
            alt="play icon"
            className="play-icon"
          />
          <video
            controls
            className="file-modal-content"
            onClick={playPauseHandler}
          >
            <source src={packSrc.url as string} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ));

    const packPhotos = urlsObj
      .filter((file) => file.fileType === "PHOTO")
      .map((packSrc, index) => (
        <div key={index} className="preview-file-container">
          <img
            src={packSrc.url as string}
            alt={`Preview ${index}`}
            className="file-modal-content"
          />
        </div>
      ));

    setModalContent(
      <div className="modal-preview-file pack-files-message">
        <div className="header-modal-preview">
          <img
            src={CloseIconGray}
            alt="close icon"
            onClick={() => setOpenUseModal(false)}
          />
        </div>
        <div className="scroll-files ">
          {packPhotos}
          {packVideos}
        </div>
      </div>
    );

    setOpenUseModal(true);
  };

  const clickMoment = () => {};

  const sendAudioToServer = async (audioBlob: Blob | null) => {
    if (!audioBlob) return;

    const audio = new File([audioBlob], `${userInfo.user_id}`, {
      type: audioBlob.type,
    });

    const prepareData = {
      chatId,
      content: "",
      price: 55,
      paidContent: true,
      files: [audio],
    };

    const response = await postUploadMedia(userInfo.access_token, prepareData);
    scrollToBottom();
  };

  const unlockContentSubmit = (messageId: string) => {
    const unlockFilesModal = (
      <div className="unlock-file-modal">
        <div className="unlock-file-modal__wrapper">
          <img src={rosesLogoPink} alt="roses logo icon" />
          <h3>Send Roses</h3>
          <p>
            Capture Your Love's Heart: <br />
            Send <b>Roses</b> Now!
          </p>
          <div className="buttons-container">
            <Button
              buttonStyle="primary"
              onClick={() => unlockContent(messageId)}
            >
              Send Roses
            </Button>
            <Button
              buttonStyle="quaternary"
              onClick={() => setOpenUseModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );

    setModalContent(unlockFilesModal);
    setOpenUseModal(true);
  };

  scrollToBottom();

  return (
    <div className="chat-container-page">
      <PageTitle
        isChat
        title={producerDetails?.displayname}
        subtitle={isContactOnline ? "Online" : "Offline"}
        avatarUrl={producerDetails?.profile?.photos?.[0]?.url}
        onBackClick={backPageHandler}
        isOnLine={isContactOnline}
        mediaButtonClick={openMediaLibrary}
        chatLocked={chatLocked}
        clickMoment={clickMoment}
        hasAMomentToSee={hasAMomentToSee}
        className="padding-hor-24"
      />

      {chatLocked ? (
        <ChatLocked onClickButton={handleCreateChatContact} />
      ) : (
        <>
          <div
            id="chat-messages-container"
            className="messages-container"
            ref={messagesContainer}
          >
            {messages?.map((message, index) => (
              <ChatMessage
                key={`${message.messageId}-key${index}`}
                side={userInfo.user_id === message.userId ? "right" : "left"}
                message={message.content}
                time={formattedTime(message?.createdAt)}
                fileSended={message.files}
                messageSettings={message.messageSettings}
                clickOnImage={imageMessageModalHandler}
                clickOnVideo={videoMessageModalHandler}
                unlockFileCallback={unlockContentSubmit}
                clickOnPack={packMessageModalHandler}
              />
            ))}
          </div>

          {isRecording ? (
            <SendAudioChat
              sendAudioCallback={sendAudioToServer}
              startRecordingCallback={setIsRecording}
              className="padding-hor-24"
            />
          ) : (
            <div className="actions-input-chat padding-hor-24">
              <ChatTextarea
                onSendMessage={handleSendMessage}
                isButtonSendDisabled={isLoading}
              />
              <PinkButton
                sendPack={contentModalPreviewPack}
                sendAudio={() => setIsRecording(true)}
                sendPhoto={contentModalPreviewPhoto}
                sendRoses={() => {}}
                sendVideo={contentModalPreviewVideo}
                isButtonsDisabled={isLoading}
                className="padding-bottom-24"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ChatPage;
