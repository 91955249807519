import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import ptBrJsonData from "../mocks/languages/pt-BR_landingPage.json";
import ptPtJsonData from "../mocks/languages/pt-PT_landingPage.json";
import enJsonData from "../mocks/languages/en_landingPage.json";
import arJsonData from "../mocks/languages/ar_landingPage.json";
import deJsonData from "../mocks/languages/de_landingPage.json";
import esESJsonData from "../mocks/languages/es-ES_landingPage.json";
import frJsonData from "../mocks/languages/fr_landingPage.json";
import hiJsonData from "../mocks/languages/hi_landingPage.json";
import itJsonData from "../mocks/languages/it_landingPage.json";
import jaJsonData from "../mocks/languages/ja_landingPage.json";
import koJsonData from "../mocks/languages/ko_landingPage.json";
import roJsonData from "../mocks/languages/ro_landingPage.json";
import ruJsonData from "../mocks/languages/ru_landingPage.json";
import ukJsonData from "../mocks/languages/uk_landingPage.json";
import zhCNJsonData from "../mocks/languages/zh-CN_landingPage.json";
import zhTWJsonData from "../mocks/languages/zh-TW_landingPage.json";
import { LandingPageTextSet } from "../types/types";

type Language =
  | "pt-BR"
  | "pt-PT"
  | "en"
  | "ar"
  | "de"
  | "es-ES"
  | "fr"
  | "hi"
  | "it"
  | "ja"
  | "ko"
  | "ro"
  | "ru"
  | "uk"
  | "zh-CN"
  | "zh-TW";

interface LanguageContextProps {
  language: Language;
  setLanguage: (language: Language) => void;
  translations: LandingPageTextSet;
}

const languageMapping: Record<Language, LandingPageTextSet> = {
  "pt-BR": ptBrJsonData,
  "pt-PT": ptPtJsonData,
  en: enJsonData,
  ar: arJsonData,
  de: deJsonData,
  "es-ES": esESJsonData,
  fr: frJsonData,
  hi: hiJsonData,
  it: itJsonData,
  ja: jaJsonData,
  ko: koJsonData,
  ro: roJsonData,
  ru: ruJsonData,
  uk: ukJsonData,
  "zh-CN": zhCNJsonData,
  "zh-TW": zhTWJsonData,
};

const languageDetect = (): Language => {
  const userLanguage = navigator.language;
  switch (userLanguage.toLowerCase()) {
    case "pt-br":
    case "pt":
      return "pt-BR";
    case "pt-pt":
      return "pt-PT";
    case "en":
      return "en";
    case "ar":
      return "ar";
    case "de":
      return "de";
    case "es":
    case "es-es":
    case "es-419":
      return "es-ES";
    case "fr":
      return "fr";
    case "hi":
      return "hi";
    case "it":
      return "it";
    case "ja":
      return "ja";
    case "ko":
      return "ko";
    case "ro":
      return "ro";
    case "ru":
      return "ru";
    case "uk":
      return "uk";
    case "zh":
    case "zh-cn":
      return "zh-CN";
    case "zh-tw":
      return "zh-TW";
    default:
      return "en";
  }
};

const LanguageContext = createContext<LanguageContextProps | undefined>(
  undefined
);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const getInitialLanguage = (): Language => {
    const storedLanguage = localStorage.getItem(
      "userLanguage"
    ) as Language | null;
    if (storedLanguage) {
      return storedLanguage;
    }
    const detectedLanguage = languageDetect();
    localStorage.setItem("userLanguage", detectedLanguage);
    return detectedLanguage;
  };

  const [language, setLanguage] = useState<Language>(getInitialLanguage());
  const [translations, setTranslations] = useState<LandingPageTextSet>(
    languageMapping[language]
  );

  useEffect(() => {
    console.log(`Idioma selecionado: ${language}`);
    localStorage.setItem("userLanguage", language);
    setTranslations(languageMapping[language]);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
