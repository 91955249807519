import { createBrowserRouter } from "react-router-dom";
import AccountLoginPage from "../pages/AccountLogin";
import CreateNumberVerifyPage from "../pages/CreateNumberVerify";
import PasswordSignInPage from "../pages/PasswordSignIn";
import ResetPasswordPage from "../pages/ResetPassword";
import AccountTypePage from "../pages/AccountType";
import UserInformation from "../pages/UserInformation";
import CreateNamePage from "../pages/CreateName";
import SendPhotoAlbumPage from "../pages/SendPhotoAlbum";
import WaitListApplicationPage from "../pages/WaitListApplication";
import UserSettingsPage from "../pages/UserSettings";
import UserSettingsPrivacyPage from "../pages/UserSettingsPrivacy";
import UserSettingsProfilePage from "../pages/UserSettingsProfile";
import AddReferralsPage from "../pages/AddReferrals";
import InviteFriendsPage from "../pages/InviteFriends";
import AllowLocationPage from "../pages/AllowLocation";
import AllowNotificationPage from "../pages/AllowNotification";
import UserSettingsAccountPage from "../pages/UserSettingsAccount";
import UserSettingsLocationPage from "../pages/UserSettingsLocation";
import UserSettingsPersonalSafetyPage from "../pages/UserSettingsPersonalSafety";
import UserSettingsMyContactsPage from "../pages/UserSettingsMyContacts";
import CreateLegalNamePage from "../pages/CreateLegalName";
import UserSettingsHoldSafePage from "../pages/UserSettingsHoldSafe";
import RecoveryEmailPage from "../pages/RecoveryEmail";
import CreateEmailVerifyPage from "../pages/CreateEmailVerify";
import CreatePasswordPage from "../pages/CreatePassword";
import GenderIdentificationPage from "../pages/GenderIdentification";
import TermsAndConditionsPage from "../pages/TermsAndConditions";
import UploadDocumentPage from "../pages/UploadDocument";
import UserSettingsWalletPage from "../pages/UserSettingsWallet";
import UserSettingsAppLanguagePage from "../pages/UserSettingsAppLanguage";
import UserSettingsDeleteAccountPage from "../pages/UserSettingsDeleteAccount";
import UserSettingsChangePasswordPage from "../pages/UserSettingsChangePassword";
import UserSettingsSubscriptionPage from "../pages/UserSettingsSubscription";
import ChatListPage from "../pages/Chat/ChatList";
import Homepage from "../pages/Homepage";
import LandingPage from "../pages/LandingPage";
import Membership from "../pages/Membership";
import WelcomeUser from "../pages/WelcomeUser";
import ChatPage from "../pages/ChatPage";
import App from "../pages/App";
import MediaLibraryPage from "../pages/MediaLibrary";
import PrivacyPolicyPage from "../pages/LandingPage/PrivacyPolicyPage";
import TermsPage from "../pages/LandingPage/TermsPage";
import USCPage from "../pages/LandingPage/USCPage";
import ProducerInteractionPage from "../components/ProducerInteraction";
import UserSettingsManageAccountPage from "../pages/UserSettingsManageAccount";
import CreateAccountPage from "../pages/CreateAccount";
import FeedPage from "../pages/Feed";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/terms-conditions",
    element: <TermsPage />,
  },
  {
    path: "/usc2257",
    element: <USCPage />,
  },
  {
    path: "/app-login",
    element: <App />,
  },
  {
    path: "/account-login",
    element: <AccountLoginPage />,
  },
  {
    path: "/create-number-verify",
    element: <CreateNumberVerifyPage />,
  },
  {
    path: "/create-account",
    element: <CreateAccountPage />,
  },
  {
    path: "/app",
    element: <Homepage />,
  },
  {
    path: "/create-legal-name",
    element: <CreateLegalNamePage />,
  },
  {
    path: "/upload-document",
    element: <UploadDocumentPage />,
  },
  {
    path: "/recovery-email",
    element: <RecoveryEmailPage />,
  },
  {
    path: "/create-email-verify",
    element: <CreateEmailVerifyPage />,
  },
  {
    path: "/send-photos",
    element: <SendPhotoAlbumPage />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditionsPage />,
  },
  {
    path: "/wait-list-application",
    element: <WaitListApplicationPage />,
  },

  {
    path: "/user-information",
    element: <UserInformation />,
  },

  {
    path: "/add-referrals",
    element: <AddReferralsPage />,
  },

	{
    path: "/welcome-user",
    element: <WelcomeUser />,
  },
  /////
  {
    path: "/membership",
    element: <Membership />,
  },
  {
    path: "/password-sign-in",
    element: <PasswordSignInPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "/account-type",
    element: <AccountTypePage />,
  },
  {
    path: "/create-name",
    element: <CreateNamePage />,
  },

  {
    path: "/user-settings",
    element: <UserSettingsPage />,
  },
  {
    path: "/user-settings-privacy",
    element: <UserSettingsPrivacyPage />,
  },
  {
    path: "/user-settings-profile",
    element: <UserSettingsProfilePage />,
  },

  {
    path: "/invite-friends",
    element: <InviteFriendsPage />,
  },
  {
    path: "/allow-location",
    element: <AllowLocationPage />,
  },
  {
    path: "/allow-notification",
    element: <AllowNotificationPage />,
  },
  {
    path: "/user-settings-account",
    element: <UserSettingsAccountPage />,
  },
  {
    path: "/user-settings-location",
    element: <UserSettingsLocationPage />,
  },
  {
    path: "/user-settings-personal-safety",
    element: <UserSettingsPersonalSafetyPage />,
  },
  {
    path: "/user-settings-holdsafe",
    element: <UserSettingsHoldSafePage />,
  },
  {
    path: "/create-password",
    element: <CreatePasswordPage />,
  },
  {
    path: "/gender-identification",
    element: <GenderIdentificationPage />,
  },
  {
    path: "/user-settings-mycontacts",
    element: <UserSettingsMyContactsPage />,
  },
  {
    path: "/user-settings-wallet",
    element: <UserSettingsWalletPage />,
  },
  {
    path: "/user-settings-language",
    element: <UserSettingsAppLanguagePage />,
  },
  {
    path: "/user-settings-delete-account",
    element: <UserSettingsDeleteAccountPage />,
  },
  {
    path: "/user-settings-change-password",
    element: <UserSettingsChangePasswordPage />,
  },
  {
    path: "/user-settings-subscriptions",
    element: <UserSettingsSubscriptionPage />,
  },
  {
    path: "/user-settings-manage-account",
    element: <UserSettingsManageAccountPage />,
  },
  {
    path: "/chat-list",
    element: <ChatListPage />,
  },

  {
    path: "/personal-chat",
    element: <ChatPage />,
  },
  {
    path: "/media-library",
    element: <MediaLibraryPage />,
  },
  {
    path: "/interaction",
    element: <ProducerInteractionPage />,
  },

  {
    path: "/feed",
    element: <FeedPage />,
  },
]);
