import React from "react";
import LogInSignUpDesk from "../components/Wrappers/LoginSignUpDesk";
import { useLoginSignUpNavigation } from "../hooks/useLoginSignUpNavigation";
import AccountLoginPage from "./AccountLogin";
import GirlWithRosesLogoEye from "../assets/images/girl-opened-month.png";
import Skeleton from "../components/Skeleton";

const App: React.FC = () => {
  const { component } = useLoginSignUpNavigation();
  return (
    <LogInSignUpDesk
      imageLeft={GirlWithRosesLogoEye}
      component={component || <AccountLoginPage />}
    />
    // <Skeleton>
    //   <>
    //     <h1 style={{height:"40px", borderRadius:"20px",margin:"20px"}}></h1>
    //   </>
    // </Skeleton>
  );
};

export default App;
