import React from "react";
import Verify from "../../../assets/icons/verify.svg";
import "./styles.scss";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import Button from "../../Buttons/Button";
import ButtonIcon from "../../Buttons/ButtonIcon";
import Rating from "../../Rating";
import ChevronDown from "../../../assets/icons/navigationIcons/chevron-down-white.svg";
import twitterIcon from "../../../assets/icons/buttonIcons/twitter-icon-gray.svg";

import photoDefault from "../../../assets/images/roses-photo-default.png";
//import photoDefault from "../../../assets/images/testing/test-profile.png";
import photo1 from "../../../assets/images/testing/teste1.png";
import photo2 from "../../../assets/images/testing/teste2.jpeg";
import photo3 from "../../../assets/images/testing/teste3.png";
import photo4 from "../../../assets/images/testing/teste4.png";
import photo5 from "../../../assets/images/testing/teste5.png";
import photo6 from "../../../assets/images/testing/teste6.png";
import photo7 from "../../../assets/images/testing/teste7.jpeg";
import photo8 from "../../../assets/images/testing/teste8.png";
import Category from "../../Category";
import ProfileDropDown from "./ProfileDropdown";

const userInfo = [
  "Brazilian",
  "Italian/Portuguese/Spanish",
  "White",
  "5’4” / 1,67m",
  "56kg / 123lb",
  "Green",
  "Brunette",
  "Female",
  "Active",
  "Yves Saint Laurent Libre",
  "Sagittarius",
  "Sushi / Italian",
  "Wine / Cocktails",
  "Smoke Sometimes",
  "Tattoos Yes",
  "Piercing Yes",
  "Pink Floyd - Wish You Were Here",
];
const userLanguage = ["Portuguese", "English", "Spanish"];
const userInterests = [
  "Sports",
  "Dancing",
  "White",
  "Fashion",
  "Travel",
  "Adventures",
];

interface ProfileDetailsProps {
  isVip: boolean;
  principalPhoto: string;
  rating: number;
  isVerified: boolean;
  name: string;
  age: string | number;
  profileDetails: string[];
  sendMessageClick: (a: any) => void;
  heartButtonClick: (a: any) => any;
  buttonStyle:
    | "heart-white-gradient"
    | "x-gradient"
    | "x-background-transparent"
    | "x-background-charcoal"
    | "x-background-white"
    | "heart"
    | "heart-white"
    | "heart-pink-gradient";
}

const NewProfile: React.FC<ProfileDetailsProps> = (props) => {
  const {
    isVip,
    principalPhoto,
    rating,
    name,
    age,
    isVerified,
    profileDetails,
    sendMessageClick,
    heartButtonClick,
    buttonStyle,
  } = props;

  return (
    <div id="profile">
      <div className="profile-banner">
        {isVip && (
          <div className="vip-tag">
            <div className="background " />
            <p className="small-p no-margin">VIP</p>
          </div>
        )}
        <img
          src={principalPhoto || photoDefault}
          alt="profile"
          loading="lazy"
        />
        <div className="profile-title">
          <div className="user-info">
            <img
              className="profile-img"
              src={principalPhoto || photoDefault}
              alt="profile"
              loading="lazy"
            />
            <div className="user-title">
              <p className="profile-info no-margin">
                {name},<span>{age}</span>{" "}
              </p>
              {isVerified && <span
    className="icon icon-verified-1 icon-md icon-blue"></span>}
            </div>
						{/* <Rating rating={rating} isVip={isVip} /> */}
						<div className="profile-statistics">
							<div className="statistics-info">
							<span
    className="icon icon-roses-solid icon-sm icon-gray"
  ></span><p className="counter">5</p>
							</div>
							<div className="statistics-info">
							<span
    className="icon icon-heart-solid icon-sm icon-gray"
  ></span><p className="counter">12.3k</p>
							</div>
							<div className="statistics-info">
							<span
    className="icon icon-photo icon-sm icon-gray"
  ></span><p className="counter">237</p>
							</div>
						</div>
          </div>
          <div className="profile-banner-buttons">
            <Button buttonStyle="quintenary" onClick={sendMessageClick}>
              SEND MESSAGE
            </Button>
            <ButtonIcon onClick={heartButtonClick} buttonStyle={buttonStyle} />
          </div>
        </div>
      </div>
      <div className="profile-bio">
        <label>About Me</label>
        <p className="small-p no-margin">
          Hey, gorgeous! 🌹 I’m Scarlet, your favorite content creator, model,
          and social media influencer.
        </p>
        <div className="category-wrapper">
          {userInfo.map((info, index) => (
            <Category key={index} children={info} />
          ))}
        </div>
        <label>Languages</label>
        <div className="category-wrapper">
          {userLanguage.map((info, index) => (
            <Category key={index} children={info} />
          ))}
        </div>
        <label>My Interests</label>
        <div className="category-wrapper">
          {userInterests.map((info, index) => (
            <Category key={index} children={info} />
          ))}
        </div>
        <div className="photo-wrapper">
          <img src={photo1} />
          <img src={photo2} />
        </div>
        <label>Social</label>
        <div className="social-buttons">
          <Button buttonStyle="icon">
            <img src={twitterIcon} />
          </Button>
          <Button buttonStyle="icon">
					<span
    className="icon icon-tiktok icon-md icon-gray"
  ></span>
          </Button>
          <Button buttonStyle="icon">
					<span
    className="icon icon-instagram icon-md icon-gray"
  ></span>
          </Button>
          <Button buttonStyle="icon">
					<span
    className="icon icon-link icon-md icon-gray"
  ></span>
          </Button>
        </div>
        <ProfileDropDown />
        <ProfileDropDown />
        <div className="photo-wrapper">
          <img src={photo3} />
          <img src={photo4} />
          <img src={photo5} />
          <img src={photo6} />
          <img src={photo7} />
          <img src={photo8} />
        </div>
      </div>
      <div className="profile-disclaimer">
			<span
    className="icon icon-messages icon-md icon-gray"
  ></span>
        <p className="profile-info small-p no-margin margin-top-8">
          SAY HELLO!
        </p>
        <p className="small-p no-margin">
          Send me a message to see all my exclusive content
        </p>
      </div>

      <Button buttonStyle="primary" onClick={sendMessageClick}>
        Send Message
      </Button>
    </div>
  );
};

export default NewProfile;
