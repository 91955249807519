import { useEffect } from "react";
import FooterLP from "../../../components/Footers/FooterLP";
import NavBar from "../../../components/Navbars/NavBar";
import { useLanguage } from "../../../contexts/languageContext";
import "./styles.scss";
import { translateTextNodes } from "../../../utils/translation";

const PrivacyPolicyPage: React.FC = () => {
	const { translations } = useLanguage();

	useEffect(() => {
    translateTextNodes(translations);
  }, [translations]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavBar />
      <section id="privacy" className="privacy-wrapper wrapper padding-top">
        <div className="privacy-wrapper-inner inner padding-hor-24">
          <h1 textkey="7/privacyPolicyTitle">
            <strong>Privacy</strong> Policy
          </h1>

          <hr />

          <p className="small-p" textkey="7/privacyPolicyDescription">
            Roses.Vip offers a sophisticated online service where members create
            and display custom advertisements in an adult entertainment
            directory. This service includes a platform for members to view
            profiles, reconnect with old friends, meet new acquaintances, share
            photographs and comments, and outline personal interests. To enhance
            the experience and facilitate community building, personal
            information is displayed to other members and visitors, enabling
            identification and network expansion. Members retain control over
            their profile information and their interactions with other members
            and the service. Roses.Vip places a high priority on maintaining
            online privacy. Any inquiries about our privacy policy can be
            addressed by contacting us at{" "}
            <a href="mailto:support@roseselite.com">support@roseselite.com</a>.
          </p>

          <h4 textkey="7/privacyPolicyTitle01">
            1. Information Collection and Use by Roses.Vip
          </h4>

          <p className="small-p" textkey="7/privacyPolicyDescription01">
            Roses.Vip is diligent in its data collection practices, gathering
            user-submitted information such as names, email addresses, and ages
            for authentication and service-related notifications. The platform
            also accrues additional profile details, including personal
            interests, gender, age, education, and occupation, which aid in
            fostering user interactions and community engagement. In terms of
            non-personal information, Roses.Vip logs data like IP addresses and
            browser types for site management, usage tracking, and service
            improvement. This data, crucial for optimizing the user experience
            and security, may also be utilized in collaboration with third
            parties to tailor services and advertisements. Care is taken to
            protect personal communication channels and full names in the
            community, emphasizing user privacy and discretion. Additionally,
            Roses.Vip involves users in promotions and contests, with clear
            communication about the collection and use of personal information
            in these contexts.
          </p>

          <h4 textkey="7/privacyPolicyTitle02">2. Use of cookies</h4>

          <p className="small-p" textkey="7/privacyPolicyDescription02">
            Roses.Vip implements cookies to optimize user experience on the
            website. These cookies serve various purposes, such as storing
            visitor preferences, recording session information, and ensuring the
            diversity of advertisements presented to users. Additionally,
            cookies are used to tailor newsletter content, advertisements, and
            web page displays based on user browser types and profile
            information. It is important to note that the information stored in
            cookies is not linked to any personally identifiable information
            submitted on the site. Users have the option to manage cookie
            settings through their browsers, but enabling cookies from Roses.Vip
            is essential for accessing most functionalities on the site.
            Roses.Vip also permits third-party advertisers on its pages to use
            cookies, which are governed by their respective privacy policies,
            distinct from the privacy policy of Roses.Vip.
          </p>

          <h4 textkey="7/privacyPolicyTitle03">3. Links</h4>

          <p className="small-p" textkey="7/privacyPolicyDescription03">
            Roses.Vip includes hyperlinks to external websites. It is important
            to clarify that Roses.Vip holds no responsibility for the privacy
            policies and practices of these linked sites. Users are advised to
            review the privacy policies of any external websites they visit, as
            these are separate from Roses.Vip’s privacy practices. The privacy
            policy applicable to Roses.Vip governs only the information
            collected directly through our website. Users should exercise due
            diligence when navigating to and interacting with third-party
            websites linked through Roses.Vip.
          </p>

          <h4 textkey="7/privacyPolicyTitle04">
            4. Chat Rooms, Weblogs, Message Boards, Directory Listings and
            Public Forums
          </h4>

          <p className="small-p" textkey="7/privacyPolicyDescription04">
            It is crucial for users to understand that any information they
            voluntarily disclose in public forums such as journals, blogs,
            message boards, classifieds, or similar platforms on Roses.Vip is
            inherently public and accessible to anyone. Such public disclosures
            may lead to this information being accessed and potentially used by
            third parties for unsolicited communications. Users are advised to
            exercise caution and discretion when sharing any personal or
            sensitive information in these public spaces, as the control over
            this information is relinquished once it is posted publicly.
            Roses.Vip is not responsible for the privacy or security of
            information that users choose to share in these public areas.
          </p>

          <h4 textkey="7/privacyPolicyTitle05">
            5. Correcting and/or Updating or Removing Information
          </h4>

          <p className="small-p" textkey="7/privacyPolicyDescription05">
            Users of Roses.Vip have the right and ability to alter or delete
            their personal information at their discretion. This can be done by
            accessing their account, where they have the option to utilize
            various features to make changes to their account information. This
            process is designed to empower users with control over their
            personal data on Roses.Vip, ensuring they have the capability to
            manage their privacy and the information presented on their
            profiles.
          </p>

          <h4 textkey="7/privacyPolicyTitle06">6. Security</h4>

          <p className="small-p" textkey="7/privacyPolicyDescription06">
            At Roses.Vip, the security of member accounts, which are protected
            by passwords created by the members themselves, is a matter of high
            priority. We implement various precautions to ensure the privacy and
            protection of account information. This includes employing
            reasonable and appropriate measures to safeguard the data within our
            database. Access to member information is stringently restricted and
            limited only to employees who require it to fulfill their job
            responsibilities, such as customer service and technical staff.
            However, it is important to acknowledge that the security of account
            information cannot be unequivocally guaranteed. Factors such as
            unauthorized access, hardware or software failures, and other
            unforeseen circumstances may pose risks to the integrity of member
            information. For further details regarding our security practices,
            we encourage reaching out to us at{" "}
            <a href="mailto:support@roseselite.com">support@roseselite.com</a>.
          </p>

          <h4 textkey="7/privacyPolicyTitle07">
            7. Sharing and Disclosure of Information Roses.Vip Collects
          </h4>

          <p className="small-p" textkey="7/privacyPolicyDescription07">
            Roses.Vip maintains a strict policy regarding the disclosure of
            personal information. Disclosure to third parties occurs only under
            necessary circumstances: compliance with legal obligations,
            enforcement of the site's Terms of Use Agreement, or ensuring public
            and user safety. In legal scenarios, such as responding to subpoenas
            or warrants, Roses.Vip will act as required by law. Additionally, in
            the event of a transfer of business ownership, personal information
            may be passed to the new owner. For a comprehensive understanding of
            our approach to personal data and any changes to this policy, users
            should refer to the relevant section of our privacy policy.
          </p>

          <h4 textkey="7/privacyPolicyTitle08">
            8. Changes in Our Privacy Policy
          </h4>

          <p className="small-p" textkey="7/privacyPolicyDescription08">
            Roses.Vip reserves the right to modify its privacy policy as needed.
            Changes will be communicated by posting updates on the website,
            ensuring users are informed about the data we collect, usage
            practices, and disclosure conditions. Users are subject to these
            amendments once they use the site after the updates. Should there be
            a significant change in the use of personally identifiable
            information, distinct from our initial collection practice, we will
            notify users by displaying a notice on our website for a period of
            30 days.
          </p>

          <h4 textkey="7/privacyPolicyTitle09">9. Contacting The Website</h4>

          <p className="small-p" textkey="7/privacyPolicyDescription09">
            For inquiries or concerns regarding the privacy statement of
            Roses.Vip, its site practices, or any interactions with this
            website, you are encouraged to reach out to us. Please direct your
            communication to{" "}
            <a href="mailto:support@roseselite.com">support@roseselite.com</a>.
            Our team is committed to providing assistance and addressing any
            queries or issues related to privacy or your experiences with our
            site.
          </p>

          <p></p>

          <p></p>
        </div>
      </section>
      <FooterLP />
    </>
  );
};

export default PrivacyPolicyPage;