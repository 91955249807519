import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import playPinkIcon from "../../assets/icons/play-pink-icon.svg";
import pausePinkIcon from "../../assets/icons/pause-pink-icon.svg";

interface ReadAudioChatProps {
  className?: string;
  audioUrl: string | null;
}

const formatSecondsToTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs.toString().padStart(2, "0")}`;
};

const ReadAudioChat: React.FC<ReadAudioChatProps> = ({
  className,
  audioUrl,
}) => {
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isReadingAudio, setIsReadingAudio] = useState(false);
  const [displayedTime, setDisplayedTime] = useState<string>("0:00"); 
  const [totalTime, setTotalTime] = useState<string>("0:00"); 
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio && audioUrl) {
      audio.onloadedmetadata = () => {
        if (audio.duration > 0 && isFinite(audio.duration)) {
          setDuration(audio.duration);
          setTotalTime(formatSecondsToTime(audio.duration));
        } else {
          console.log("Error");
        }
      };

      audio.ontimeupdate = () => {
        if (audio.duration > 0) {
          setCurrentTime(audio.currentTime);
          setDisplayedTime(formatSecondsToTime(audio.currentTime));
        }
      };

      audio.onended = () => {
        setIsReadingAudio(false);
        setCurrentTime(0);
        setDisplayedTime("0:00");
      };
    }

    return () => {
      if (audio) {
        audio.onloadedmetadata = null;
        audio.ontimeupdate = null;
        audio.onended = null;
      }
    };
  }, [audioUrl]);

  const playAudioHandler = () => {
    const audio = audioRef.current;

    if (audio) {
      if (isReadingAudio) {
        audio.pause();
        setIsReadingAudio(false);
      } else {
        audio.play();
        setIsReadingAudio(true);
      }
    }
  };

  return (
    <div className={`read-audio-chat-component ${className ? className : ""}`}>
      <div className="player-audio-component">
        <button className="play-pause-button" onClick={playAudioHandler}>
          <img
            src={isReadingAudio ? pausePinkIcon : playPinkIcon}
            alt={isReadingAudio ? "pause icon pink" : "play icon pink"}
          />
        </button>
        <div className="progress-bar-wrapper">
          <div className="progress-bar" />
          <div
            className="slider-handle"
            style={{
              left: `${duration > 0 ? (currentTime / duration) * 100 : 0}%`,
            }}
          />
          <p className="audio-size-paragraph">{displayedTime} / {totalTime}</p>
        </div>
        {audioUrl && (
          <audio ref={audioRef} src={audioUrl} style={{ display: "none" }} />
        )}
      </div>
    </div>
  );
};

export default ReadAudioChat;
