import { useEffect, useState } from "react";
import Header from "../../components/Headers/Header";
   
import "./styles.scss";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import Select from "../../components/Selects/Select";
import ListItem from "../../components/Lists/ListItem";
import TitleText from "../../components/Texts/TitleText";
 
import { useAuth } from "../../hooks/auth";
import postCreateUser from "../../api/auth/postCreateUser";
import PatchUserSelfDetailApi from "../../api/patchUserSelfDetail";
import Toast from "../../components/Toast";
import { ClipLoader } from "react-spinners";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useNavigate } from "react-router-dom";
import createUser from "../../api/auth/postCreateUser";

const genders = [
  { label: "Man", value: "MAN" },
  { label: "Woman", value: "WOMAN" },
  { label: "Non-Binary", value: "NONBINARY" },
  { label: "Trans", value: "TRANS" },
];

const GenderIdentificationPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const [genderConnect, setGenderConnect] = useState([
    { id: 1, label: "Man", value: false },
    { id: 2, label: "Woman", value: false },
    { id: 3, label: "Non-Binary", value: false },
    { id: 4, label: "Trans", value: false },
  ]);

  const { setComponent } = useLoginSignUpNavigation();
  const { userInfo, setUserInfo } = useUserInfo();
  const navigate = useNavigate();
  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const handleGenderConnect = (id: number) => {
    const updatedCheckboxes = genderConnect.map((gender) =>
      gender.id === id ? { ...gender, value: !gender.value } : gender
    );
    setGenderConnect(updatedCheckboxes);
  };

  const addGenderSubmit = async () => {
    setLoading(true);

    const createdUserData = {
      displayname: userInfo.display_name,
      phone: userInfo.user_phone?.replace("+", ""),
      email: userInfo.user_email,
      password: sessionStorage.getItem("pass") || "",
      gender: selectedGender,
      preferedGenders: genderConnect
        .filter((item) => item.value)
        .map((item) => {
          if (item.label === "Non-Binary") {
            return "NONBINARY";
          } else {
            return item.label.toUpperCase();
          }
        }),
    };

    const createUserResponse = await createUser(createdUserData);

    switch (createUserResponse.status) {
      case 201:
        const accessToken = createUserResponse.res.access_token;

        setUserInfo("access_token", accessToken);
        sessionStorage.setItem("pass", "null");

        const userDetails = {
          isActive: true,
        };

        const activateResponse = await PatchUserSelfDetailApi(
          accessToken,
          userDetails
        );

        switch (activateResponse.status) {
          case 200:
            navigate("/home");

            break;
          default:
            setErrorMessage(
              "Something went wrong on user activate. Please try again."
            );
            setHasError(true);
            break;
        }

        break;

      default:
        setErrorMessage(
          "Something went wrong on user creation. Please try again."
        );
        setHasError(true);

        setTimeout(() => setHasError(false), 3000);
        break;
    }

    setLoading(false);
  };

  return (
       <div className="gender-identification">
      <div>
        <Header
          headerIcon="heart"
          backButtonClick={() => setComponent("/allow-notification")}
          title="I identify as..."
          subTitle={`Everyone is welcomed on our platform.`}
        />
        <Select
          value={selectedGender}
          onChange={(e) => setSelectedGender(e.target.value)}
          options={genders}
          defaultValueText="select a gender"
        />
        <TitleText>
          ...and want to <br />
          connect with
        </TitleText>
        <SubText>You can change this later.</SubText>

        {genderConnect.map((gender, index) => (
          <ListItem
            key={index}
            showCheckboxLeft
            showLabel
            labelContent={gender.label}
            checkBoxLeftHandler={() => handleGenderConnect(gender.id)}
            showSeparator={genderConnect.length > index + 1}
          />
        ))}
      </div>

      <Button buttonStyle={"primary"} onClick={addGenderSubmit}>
        {loading ? (
          <ClipLoader color={"#FFF"} loading={true} size={15} />
        ) : (
          "Continue"
        )}
      </Button>

      <Toast
        type="error"
        description={errorMessage}
        setIsVisible={setHasError}
        isVisible={hasError}
        title="Error"
      />
    </div>
  );
};

export default GenderIdentificationPage;
