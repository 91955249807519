import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import micIconPink from "../../assets/icons/mic-icon-pink.svg";
import micIconGray from "../../assets/icons/mic-icon-gray.svg";
import closeIconCircleGray from "../../assets/icons/closeIcons/close-icon-circle-gray.svg";
import PaperAirplane from "../../assets/icons/paper-air-plane.svg";

interface SendAudioChatProps {
  startRecordingCallback: React.Dispatch<React.SetStateAction<boolean>>;
  sendAudioCallback: (audioBlob: Blob | null) => void;
  className?: string;
}

const SendAudioChat: React.FC<SendAudioChatProps> = (props) => {
  const { startRecordingCallback, sendAudioCallback, className } = props;
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [timeRecording, setTimeRecording] = useState("0:00");

  useEffect(() => {
    sendAudioCallback(null);
  }, [sendAudioCallback]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60); 
    const seconds = Math.floor(time % 60); 
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    let elapsedTime = 0;

    if (isRecording) {
      intervalId = setInterval(() => {
        elapsedTime += 0.1; 
        setTimeRecording(formatTime(elapsedTime)); 
      }, 100);
    } else {
      setTimeRecording("0:00"); 
    }

    return () => {
      if (intervalId) clearInterval(intervalId); 
    };
  }, [isRecording]);

  const startRecordingAudio = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event: BlobEvent) => {
        const newBlob = event.data;
        sendAudioCallback(newBlob);
      };

      mediaRecorderRef.current.start();
    setIsRecording(true);
    startRecordingCallback(true);
    } catch (error) {
      console.error(error);
    }
  };

  const cancelRecordingAudio = () => {
    try {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      sendAudioCallback(null);
      setIsRecording(false);
      startRecordingCallback(false);
    } catch (error) {
      console.error(error);
    }
  };

  const sendAudioHandler = () => {
    try {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      setIsRecording(false);
      startRecordingCallback(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`send-audio-chat-component ${className}`}>
      <div className="audio-recorder">
        <img
          src={closeIconCircleGray}
          alt="close icon gray"
          onClick={cancelRecordingAudio}
        />

        {isRecording ? (
          <>
            <p>{timeRecording}</p>
            <img
              src={micIconPink}
              alt="mic icon pink"
              className="grid-colum-3-4"
            />
            <img
              src={PaperAirplane}
              alt="paper airplane"
              className="grid-colum-4-5"
              onClick={sendAudioHandler}
            />
          </>
        ) : (
          <img
            onClick={startRecordingAudio}
            src={micIconGray}
            alt="mic icon gray"
            className="grid-colum-4-5"
          />
        )}
      </div>
    </div>
  );
};

export default SendAudioChat;
