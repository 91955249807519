import React, { ChangeEvent, useEffect, useState } from "react";
import "./styles.scss";
import Camera from "../../assets/icons/camera-icon-gray.svg";
import CloseIconPeach from "../../assets/icons/closeIcons/close-icon-peach.svg";

interface PhotosPlaceholderProps {
  quantityFiles: number;
  photoAlbumCallback: (files: (File | null)[]) => void;
  removeFileCallback?: (fileId: string) => void;
  images?: { fileId: string; url: string }[];
}

const PhotosPlaceholder: React.FC<PhotosPlaceholderProps> = (props) => {
  const { quantityFiles, photoAlbumCallback, removeFileCallback, images } =
    props;
  const [files, setFiles] = useState<(File | null)[]>(
    Array(quantityFiles).fill(null)
  );

  useEffect(() => {
    if (images) {
      const newFiles = Array(quantityFiles).fill(null);
      images.forEach((image, index) => {
        if (index < quantityFiles) {
          newFiles[index] = null;
        }
      });
      setFiles(newFiles);
    }
  }, [images, quantityFiles]);

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newFiles = [...files];
    if (e.target.files && e.target.files[0]) {
      newFiles[index] = e.target.files[0];
      setFiles(newFiles);
      photoAlbumCallback(newFiles);
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    if (images && images[index]) {
      removeFileCallback?.(images[index].fileId);
    }
    newFiles[index] = null;
    setFiles(newFiles);
    photoAlbumCallback(newFiles);
  };

  return (
    <div id="photos-placeholder">
      {Array.from({ length: quantityFiles }).map((_, index) => (
        <div className="photo-preview" key={index}>
          {images && images[index] ? (
            <div className="preview-images">
              <img src={images[index].url} alt={`preview ${index}`} />
              <button onClick={() => handleRemoveFile(index)}>
                <img src={CloseIconPeach} alt="remove icon" />
              </button>
            </div>
          ) : (
            <>
              {!files[index] && (
                <span>
                  <img src={Camera} alt="camera icon" />
                </span>
              )}

              <input
                type="file"
                name="upload-photo"
                onChange={(e) => handleFileChange(e, index)}
								accept=".jpg,.jpeg,.png"
              />
              {files[index] && (
                <div className="preview-images">
                  <img
                    src={URL.createObjectURL(files[index] as File)}
                    alt={`preview ${index}`}
                  />
                  <button onClick={() => handleRemoveFile(index)}>
                    <img src={CloseIconPeach} alt="remove icon" />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default PhotosPlaceholder;
