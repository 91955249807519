import React, { ChangeEvent, KeyboardEvent, useRef, useState } from "react";
import "./styles.scss";
import PaperAirplane from "../../../assets/icons/paper-air-plane.svg";

interface ChatTextareaProps {
  onSendMessage: (message: string) => void;
  hasEmptyMessage?: boolean;
  isButtonSendDisabled?: boolean;
  autoFocus?: boolean;
	className?:string
}

const ChatTextarea: React.FC<ChatTextareaProps> = (props) => {
  const {
    onSendMessage,
    hasEmptyMessage = false,
    isButtonSendDisabled,
		autoFocus,
		className
  } = props;

  const [message, setMessage] = useState<string>("");

  const chatTextareaRef = useRef<HTMLTextAreaElement | null>(null);

  const adjustHeight = () => {
    if (chatTextareaRef.current) {
      chatTextareaRef.current.style.height = "50px";

      if (chatTextareaRef.current.scrollHeight >= 61) {
        chatTextareaRef.current.style.height = `${Math.min(
          chatTextareaRef.current.scrollHeight,
          100
        )}px`;

        const scrollHeight = chatTextareaRef.current.scrollHeight;
        chatTextareaRef.current.style.height = `${Math.max(
          scrollHeight,
          48
        )}px`;
        chatTextareaRef.current.scrollTop =
          chatTextareaRef.current.scrollHeight;
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
    adjustHeight();
  };

  const handleSend = () => {
    if (!isButtonSendDisabled) {
      if (message.trim() || hasEmptyMessage) {
        onSendMessage(message);
        setMessage("");
        if (chatTextareaRef.current) {
          chatTextareaRef.current.style.height = "50px";
        }
      }
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };
  return (
    <div id="chat-textarea" className={className}>
      <textarea
        ref={chatTextareaRef}
        value={message}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        placeholder="Send message"
        className="textarea margin-vert-16"
        autoFocus={autoFocus}
      />
      <button
        className="paper-airplane-button"
        onClick={handleSend}
        disabled={isButtonSendDisabled}
      >
        <img src={PaperAirplane} alt="paper airplane" />
      </button>
    </div>
  );
};

export default ChatTextarea;
