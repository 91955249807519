import React, { ChangeEvent, forwardRef, ReactNode } from "react";
import "./styles.scss";
import VMasker from "vanilla-masker";
import Loupe from "../../../assets/icons/loupe-gray.svg";

interface InputTextProps {
  inputStyle?: string;
  mask?: string;
  value?: string;
  onChangeInput?: (a: string) => void;
  iconLeft?: string;
  iconRight?: string;
  label?: ReactNode;
  inputType?: React.HTMLInputTypeAttribute | undefined;
  searchInput?: boolean;
  textAlign?: "start" | "end" | "left" | "right" | "center";
  isWarning?: boolean;
  textTransform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "full-width"
    | "full-size-kana";
  placeholder?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  disabled?: boolean | undefined;
  className?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
	readOnly?:boolean;
}

const InputText = forwardRef<HTMLInputElement, InputTextProps>((props, ref) => {
  const {
    inputStyle,
    value = "",
    mask,
    onChangeInput,
    iconRight,
    iconLeft,
    label,
    searchInput,
    textAlign,
    inputType,
    isWarning,
    textTransform = "none",
    placeholder,
    onKeyDown,
    disabled,
    className,
    onFocus,
		readOnly
  } = props;

  const handlerInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (mask) {
      onChangeInput?.(VMasker.toPattern(inputValue, mask));
    } else {
      onChangeInput?.(inputValue);
    }
  };

  return (
    <div id="input-text-default" className="">
      {label && <label className="input">{label}</label>}
      <div className="input-container">
        {searchInput ? (
          <img className="input-icon-left" src={Loupe} alt="loupe icon" />
        ) : iconLeft ? (
          <img className="input-icon-left" src={iconLeft} alt="" />
        ) : (
          false
        )}
        <input
				readOnly={readOnly}
          ref={ref}
          onFocus={onFocus}
          disabled={disabled}
          className={`${className} ${
            isWarning ? " isWarning" : ""
          } input-text ${inputStyle}`}
          value={value}
          onChange={handlerInput}
          type={inputType ?? "text"}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          style={{
            textAlign: textAlign,
            paddingRight: searchInput || iconRight ? 40 : 16,
            paddingLeft: searchInput || iconLeft ? 40 : 16,
            textTransform: textTransform,
          }}
        />
        {iconRight && (
          <img className="input-icon-right" src={iconRight} alt="right icon" />
        )}
      </div>
    </div>
  );
});

export default InputText;
