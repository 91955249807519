import fetchApi from "../fetchApi";

const getListFavorites = async (token: string) => {
  return await fetchApi({
    service: "user",
    endPoint: `/favorites`,
    method: "GET",
    token,
  });
};

export default getListFavorites;
