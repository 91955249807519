import { servicesApi } from "../fetchApi/services-api";


export type PhoneChecker = ReturnType<
  ReturnType<typeof VerifyPhoneWpp>["createVerification"]
>;

const VerifyPhoneWpp = () => {
  const wppRoute = `${servicesApi("whatsappCode")}/send`;
  const wppVerifyRoute = `${servicesApi("whatsappCode")}/verify`;

  const createVerification = (phone: string) => {
    const requestCode = () =>
      fetch(`${wppRoute}`, {
        method: "POST",
        body: JSON.stringify({ phone }),
        headers: {
          ["Content-Type"]: "application/json",
        },
      });

    const verifyCode = (code: string) =>
      fetch(`${wppVerifyRoute}`, {
        method: "POST",
        body: JSON.stringify({ phone, code }),
        headers: {
          ["Content-Type"]: "application/json",
        },
      });

    const getPhone = () => phone;

    return {
      requestCode,
      verifyCode,
      getPhone,
    };
  };

  return {
    createVerification,
  };
};

export default VerifyPhoneWpp();


// import fetchApi from "../fetchApi";

// const createVerification = async (phone: string) => {
//   return await fetchApi({
//     service: "whatsappCode",
//     endPoint: "/send",
//     method: "POST",
//     data: { phone },
//   });
// };

// const verifyCode = async (phone: string, code: string) => {
//   return await fetchApi({
//     service: "whatsappCode",
//     endPoint: "/verify",
//     method: "POST",
		
//     data: { phone, code },
	
//   });
// };

// const wppVerificationService = {
//   createVerification,
//   verifyCode,
// };

// export default wppVerificationService;
