import React from "react";
import ReactDOM from "react-dom/client";
//NAO MUDAR A ORDEM DOS IMPORTS DE SCSS
import "./scss/reset.scss";
import "./scss/icons.scss";
import "./scss/var.scss";
import "./scss/global.scss";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import AppProvider from "./hooks";
import { LanguageProvider } from "./contexts/languageContext";
import { WebSocketProvider } from "./contexts/webSocketContext";
import { initializeApp } from "firebase/app";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const firebaseConfig = {
  apiKey: "AIzaSyBfpzG7NcAdYUFiWDIKrln_bSbZT3FZIyI",
  authDomain: "roses--main.firebaseapp.com",
  projectId: "roses--main",
  storageBucket: "roses--main.appspot.com",
  messagingSenderId: "961345023378",
  appId: "1:961345023378:web:20fd72bf30e3b1d48ea54b",
  measurementId: "G-WXX5KQBN5X",
};

const app = initializeApp(firebaseConfig);

root.render(
  // <React.StrictMode>
    <AppProvider>
      <WebSocketProvider>
        <LanguageProvider>
          <RouterProvider router={router} />
        </LanguageProvider>
      </WebSocketProvider>
    </AppProvider>
  // </React.StrictMode>
);
