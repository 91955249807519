import React from "react";
import "./styles.scss";
import Button from "../Buttons/Button";
import cameraIconGray from "../../assets/icons/camera-gray.svg";
import imagesIconGray from "../../assets/icons/images-icon-gray.svg";
import lockIconGray from "../../assets/icons/lock-icon-gray.svg";

interface LockedFilesChatProps {
  rosesPrice: string | number;
  message: string;
  unlockClick: (a: any) => void;
  lockedImageBlurred: string;
  qntPhotos: string | number;
  qntVideos: string | number;
}

const LockedFilesChat: React.FC<LockedFilesChatProps> = (props) => {
  const {
    rosesPrice,
    message,
    unlockClick,
    lockedImageBlurred,
    qntPhotos,
    qntVideos,
  } = props;
  return (
    <div className="locked-files-chat">
      {/* <img src={lockedImageBlurred} alt="locked images" /> */}
      <div className="content-info-container">
        <span className="text-icon">
          <img src={cameraIconGray} alt="camera icon" />
          <p className="no-margin">{qntVideos}</p>
        </span>
        <span className="text-icon">
          <img src={imagesIconGray} alt="images icon" />
          <p className="no-margin">{qntPhotos}</p>
        </span>
      </div>
      <div className="main-content padding-24">
        <img src={lockIconGray} alt="lock icon" />
        <h4 className="no-margin">{rosesPrice}</h4>
        <p className="no-margin">{message}</p>
      </div>
      <Button
        className="button-locked-files"
        buttonStyle="quintenary"
        onClick={unlockClick}
      >
        Unlock Content
      </Button>
    </div>
  );
};

export default LockedFilesChat;
