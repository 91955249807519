import { PhotosAlbumType } from "../../types/files";
import { servicesApi } from "../fetchApi/services-api";

const postUploadProfilePhotos = async (
  token: string,
  images: PhotosAlbumType
) => {
  const apiRoute = `${servicesApi("user")}/upload-profile-photos`;

  const formData = new FormData();

  if (Array.isArray(images)) {
    images.forEach((image) => {
      formData.append("files", image);
    });
  } else {
    formData.append("files", images);
  }

  try {
    const response = await fetch(apiRoute, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const res = await response.json();
    return { res, status: response.status };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export default postUploadProfilePhotos;
