import React, { ReactNode, useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ButtonNavigation from "../../components/Buttons/ButtonNavigation";
import InputText from "../../components/Inputs/InputText";
import SubText from "../../components/Texts/SubText";
import PhotosPlaceholder from "../../components/PhotosPlaceholder";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import disclaimerIcon from "../../assets/icons/union-gray.svg";
import closePeachIcon from "../../assets/icons/closeIcons/close-icon-peach.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import InfoIconGray from "../../assets/icons/union-gray.svg";
import { UserInfoRequest } from "../../types/userInfoRequest";
import SlidUp from "../../components/Modals/SlidUp";
import getUserInfo from "../../api/userInfo/getUserInfo";
import getProfileDetails from "../../api/profileDetails/getProfileDetails";
import Button from "../../components/Buttons/Button";
import patchProfileDetails from "../../api/profileDetails/patchProfileDetails";
import { textCapitalize } from "../../utils/textCapitalize";
import InputMessage from "../../components/TextAreas/Textarea";
import postUploadProfilePhotos from "../../api/profileDetails/postUploadProfilePhotos";
import deleteProfilePhotos from "../../api/profileDetails/deleteprofilePhotos";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import { dateFormat } from "../../utils/dateFormat";
import DropDown from "../../components/Lists/DropDown";

interface ListDetails {
  name: string;
  profileDetailId: number;
  type: DetailsType;
}

type DetailsType =
  | "eye_color"
  | "hair_color"
  | "nationality"
  | "ethnicity"
  | "language"
  | "gender"
  | "age"
  | "height"
  | "weight"
  | "language"
  | "occupation"
  | "marital_status"
  | "net_worth";

const UserSettingsProfilePage: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [photosAlbum, setPhotosAlbum] = useState<(File | null)[] | []>([]);
  const [isSlidUpVisible, setIsSlidUpVisible] = useState(false);
  const [isSlidUpWriteVisible, setIsSlidUpWriteVisible] = useState(false);

  const [userInfoRequest, setUserInfoRequest] =
    useState<UserInfoRequest | null>(null);
  const [eyeColorList, setEyeColorList] = useState<ListDetails[] | []>([]);
  const [hairColorList, setHairColorList] = useState<ListDetails[] | []>([]);
  const [nationalityList, setNationalityList] = useState<ListDetails[] | []>(
    []
  );
  const [ethnicityList, setEthnicityList] = useState<ListDetails[] | []>([]);
  const [languageList, setLanguageList] = useState<ListDetails[] | []>([]);
  const [genderList, setGenderList] = useState<string[] | []>([]);
  const [titleSlidUp, setTitleSlidUp] = useState("");
  const [profileBio, setProfileBio] = useState("");
  const [profileBirthday, setProfileBirthday] = useState("");
  const [profileHeight, setProfileHeight] = useState("");
  const [profileWeight, setProfileWeight] = useState("");
  const [openWriteInput, setOpenWriteInput] = useState("");

  const [modalSlidUpContent, setModalSlidUpContent] =
    useState<ReactNode | null>(null);
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo } = useUserInfo();

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  useEffect(() => {
    const requestProfileDetails = async () => {
      const response = await getProfileDetails(userInfo.access_token);

      setEyeColorList(
        response.res
          .filter((detail: ListDetails) => detail.type === "eye_color")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setHairColorList(
        response.res
          .filter((detail: ListDetails) => detail.type === "hair_color")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setNationalityList(
        response.res
          .filter((detail: ListDetails) => detail.type === "nationality")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setEthnicityList(
        response.res
          .filter((detail: ListDetails) => detail.type === "ethnicity")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setLanguageList(
        response?.res?.filter(
          (detail: ListDetails) => detail.type === "language"
        )
      );

      setGenderList(["Man", "Woman", "Non-binary", "Trans"]);
    };

    requestProfileDetails();
  }, [userInfo]);

  useEffect(() => {
    requestUserInfo();
  }, [isSlidUpVisible, isSlidUpWriteVisible]);

  const patchDetails = async (
    key: string,
    value: string | string[] | number
  ) => {
    return await patchProfileDetails(userInfo.access_token, key, value);
  };

  const requestUserInfo = async () => {
    const response = await getUserInfo(userInfo.access_token);

    setUserInfoRequest(response.res);
  };

  const applyChangeDetailRequestListed = async (
    detail: string,
    valueSelected: string
  ) => {
    let type =
      detail === "gender"
        ? "gender"
        : detail === "ethnicity"
        ? "profileEthnicity"
        : detail === "eye_color"
        ? "profileEyeColor"
        : detail === "hair_color"
        ? "profileHairColor"
        : detail === "nationality"
        ? "profileNationality"
        : false;

    let value =
      detail === "gender"
        ? valueSelected?.toUpperCase()
        : valueSelected?.toLowerCase();

    if (type) {
      await patchDetails(type, value);
    }

    setIsSlidUpVisible(false);
  };

  const changeListItemHandler = (toChange: DetailsType) => {
    const detailsSlide =
      toChange === "gender"
        ? { list: genderList, title: "Gender" }
        : toChange === "ethnicity"
        ? { list: ethnicityList, title: "Ethnicity" }
        : toChange === "eye_color"
        ? { list: eyeColorList, title: "Eye Color" }
        : toChange === "hair_color"
        ? { list: hairColorList, title: "Hair Color" }
        : toChange === "nationality"
        ? { list: nationalityList, title: "Nationality" }
        : { list: [], title: "" };

    setTitleSlidUp(detailsSlide?.title);

    setIsSlidUpVisible(true);
    let valueSelected: string;
    const selectedValueHandler = (value: string) => {
      valueSelected = value;
    };
    setModalSlidUpContent(
      <>
        {detailsSlide.list?.map((detail: any) => (
          <ListItemSecondary
            key={detail}
            showLabel
            labelContent={textCapitalize(detail)}
            showRadioRight
            radioRightGroupName={toChange}
            radioRightValue={detail}
            onChangeRightRadioCallback={selectedValueHandler}
            showSeparator
          />
        ))}

        <Button
          onClick={() =>
            applyChangeDetailRequestListed(toChange, valueSelected)
          }
          buttonStyle="primary"
        >
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpVisible(false)}
        >
          Cancel
        </Button>
      </>
    );
  };

  // const writeItemHandler = (toChange: "weight" | "height" | "birthday") => {
  //   let key = toChange === "birthday" ? "birthDate" : toChange;
  //   setTitleSlidUp(toChange);

  //   setModalSlidUpContent(
  //     <>
  //       <Button onClick={() => addSpecification(key)} buttonStyle="primary">
  //         Apply
  //       </Button>
  //       <Button
  //         buttonStyle="secondary"
  //         onClick={() => setIsSlidUpVisible(false)}
  //       >
  //         Cancel
  //       </Button>
  //     </>
  //   );
  //   setIsSlidUpVisible(true);
  // };

  const addListLanguage = async (lang: string[]) => {
    const response = await patchDetails("profileLanguages", lang);

    switch (response.status) {
      case 200:
        setIsSlidUpVisible(false);
        break;
    }
  };

  const addSpecification = async () => {
    let key = openWriteInput === "birthday" ? "birthDate" : openWriteInput;
    let value =
      openWriteInput === "weight"
        ? Number(profileWeight)
        : openWriteInput === "height"
        ? Number(profileHeight)
        : profileBirthday;

    const response = await patchDetails(key, value);
    switch (response.status) {
      case 200:
        setIsSlidUpWriteVisible(false);
        break;
    }
  };

  const profileBioChange = (e: any) => {
    setProfileBio(e);
  };
  const profileDisplayNameChange = (e: any) => {
    setProfileBio(e);
  };

  const addPhotoHandler = async (files: (File | null)[]): Promise<void> => {
    setPhotosAlbum(files);
    const imageToUpload = files.filter((file) => file !== null);
    const photo = imageToUpload[imageToUpload.length - 1];
    if (photo) {
      await postUploadProfilePhotos(userInfo.access_token, photo);
      requestUserInfo();
    }
  };

  const removePhotoHandler = async (id: string) => {
    await deleteProfilePhotos(userInfo.access_token, id);
    requestUserInfo();
  };

  const selectLanguageHandler = () => {
    let selectedLanguageList: string[] = [];
    const handleCheckboxRightChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = event.target.value;

      if (event.target.checked) {
        if (!selectedLanguageList.includes(value)) {
          selectedLanguageList = [...selectedLanguageList, value];
        }
      } else {
        selectedLanguageList = selectedLanguageList.filter(
          (lang) => lang !== value
        );
      }
    };

    setTitleSlidUp("Language");

    setModalSlidUpContent(
      <>
        {languageList.map(
          (
            lang: { name: string; profileDetailId: number; type: string },
            index
          ) => (
            <ListItemSecondary
              key={index}
              showLabel
              labelContent={textCapitalize(lang.name)}
              showCheckboxRight
              checkBoxRightHandler={handleCheckboxRightChange}
              checkboxRightValue={lang.name}
              // setCheckRightSelect={setSelectedLanguageList}
              checkRightSelect={selectedLanguageList}
              showSeparator
            />
          )
        )}

        <Button
          onClick={() => addListLanguage(selectedLanguageList)}
          buttonStyle="primary"
        >
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpVisible(false)}
        >
          Cancel
        </Button>
      </>
    );
    setIsSlidUpVisible(true);
  };

  const language = [
    {
      value: "a",
    },
    { value: "b" },
    { value: "c" },
  ];

  return (
    <div className="user-settings-profile">
      <PageTitle
        title={"Profile"}
        subtitle={"Configuration"}
        onBackClick={() => setPrincipalScreen("/user-settings")}
        hasCloseIcon={false}
      />
      <ButtonNavigation
        setActiveSection={handleSectionChange}
        activeSection={activeSection}
        buttonCount={3}
        buttonLabels={["Info", "Photos", "Preference"]}
        className="margin-vert-24"
      />
      {activeSection === "button1" && (
        <>
          {/* <AccountVerification
            labelContent={"Identity Verification"}
            descriptionContent={
              "Let’s make sure nobody is trying to impersonate you."
            }
            showVerifyIcon={true}
            iconContent={verifyGrayIcon}
          /> */}
          <div className="input-wrapper margin-hor-16">
            <InputText
              value={userInfo.display_name}
              placeholder="Display Name"
              onChangeInput={profileDisplayNameChange}
              label={
                <>
                  Display Name <img src={InfoIconGray} alt="info icon" />
                </>
              }
            />
            <InputMessage
              label="Bio"
              placeholder="About me..."
              value={userInfo.bio || profileBio}
              onChangeInput={profileBioChange}
            />
            {/* <SubText
              children={`This will be shown on your profile. You can change
								 your display name once every 30 days.`}
              icon={disclaimerIcon}
            /> */}
          </div>
        </>
      )}

      {activeSection === "button1" && (
        <>
          <div className="list-container margin-hor-24">
            <ListItemSecondary
              showLabel={true}
              labelContent="Gender"
              showIconRight={true}
              showValue={true}
              valueContent={textCapitalize(userInfoRequest?.gender) || "Add"}
              iconRightContent={chevronRightIcon}
              showSeparator={true}
              clickAction={() => changeListItemHandler("gender")}
            />
            <ListItemSecondary
              showLabel={true}
              labelContent="Birthday"
              showIconRight={true}
              showValue={true}
              valueContent={dateFormat(userInfoRequest?.birthDate) || "Add"}
              iconRightContent={chevronRightIcon}
              showSeparator={true}
              clickAction={() => {
                setIsSlidUpWriteVisible(true);
                setOpenWriteInput("birthday");
              }}
            />
            <ListItemSecondary
              showLabel={true}
              labelContent="Eye color"
              showIconRight={true}
              showValue={true}
              valueContent={
                textCapitalize(userInfoRequest?.eyeColor?.name) || "Add"
              }
              iconRightContent={chevronRightIcon}
              showSeparator={true}
              clickAction={() => changeListItemHandler("eye_color")}
            />
            <ListItemSecondary
              showLabel={true}
              labelContent="Hair color"
              showIconRight={true}
              showValue={true}
              valueContent={
                textCapitalize(userInfoRequest?.hairColor?.name) || "Add"
              }
              iconRightContent={chevronRightIcon}
              showSeparator={true}
              clickAction={() => changeListItemHandler("hair_color")}
            />
            <ListItemSecondary
              showLabel={true}
              labelContent="Ethnicity"
              showIconRight={true}
              showValue={true}
              valueContent={
                textCapitalize(userInfoRequest?.ethnicity?.name) || "Add"
              }
              iconRightContent={chevronRightIcon}
              showSeparator={true}
              clickAction={() => changeListItemHandler("ethnicity")}
            />

            <ListItemSecondary
              showLabel={true}
              labelContent="Nationality"
              showIconRight={true}
              showValue={true}
              valueContent={
                textCapitalize(userInfoRequest?.nationality?.name) || "Add"
              }
              iconRightContent={chevronRightIcon}
              showSeparator={true}
              clickAction={() => changeListItemHandler("nationality")}
            />
            <ListItemSecondary
              showLabel={true}
              labelContent="Height"
              showIconRight={true}
              showValue={true}
              valueContent={userInfoRequest?.height || "Add"}
              iconRightContent={chevronRightIcon}
              showSeparator={true}
              clickAction={() => {
                setIsSlidUpWriteVisible(true);

                setOpenWriteInput("height");
              }}
            />
            <ListItemSecondary
              showLabel={true}
              labelContent="Weight"
              showIconRight={true}
              showValue={true}
              valueContent={userInfoRequest?.weight || "Add"}
              iconRightContent={chevronRightIcon}
              showSeparator={true}
              clickAction={() => {
                setOpenWriteInput("weight");
                setIsSlidUpWriteVisible(true);
              }}
            />

            <ListItemSecondary
              showLabel={true}
              labelContent="Language"
              showIconRight={true}
              showValue={true}
              valueContent={
                userInfoRequest?.languages?.length
                  ? userInfoRequest.languages
                      .map((lang) => textCapitalize(lang.name))
                      .join(", ")
                  : "Add"
              }
              iconRightContent={chevronRightIcon}
              showSeparator={true}
              clickAction={selectLanguageHandler}
            />
          </div>
        </>
      )}

      {activeSection === "button2" && (
        <>
          <div className="photo-container margin-hor-16">
            <PhotosPlaceholder
              quantityFiles={9}
              photoAlbumCallback={addPhotoHandler}
              images={userInfoRequest?.photos}
              removeFileCallback={removePhotoHandler}
            />
            <p className="caption no-margin">
              {userInfoRequest?.photos?.length} / 9 photos
            </p>
          </div>
          <div className="list-container">
            <ListItemSecondary
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Preserve my Confidentiality"
              showDescription={true}
              descriptionContent="Conceal my facial identity"
              showToggle={true}
              showSeparator={true}
            />
            <ListItemSecondary
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Smart Photo"
              showDescription={true}
              descriptionContent="Chooses best photo for you"
              showToggle={true}
            />
          </div>
          <SubText
            children={`We welcome sensual expression that's artistic and 
							sophisticated. Please avoid explicit content to maintain the 
							respectful and tasteful spirit of our community.`}
            icon={disclaimerIcon}
          />
        </>
      )}

      {activeSection === "button3" && (
        <>
          <InputText
            value={"My Current Location"}
            placeholder="Location"
            onChangeInput={() => {}}
          />
          {/* <DropDown
            inputChange={() => {}}
            inputValue="xxx"
            options={language}
          /> */}
          <div className="list-container">
            {/* <ListItemSecondary
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Distance"
              showSlider={true}
							minSliderValue={10}
							maxSliderValue={500}
							callBackSlider={setDistancePreference}
							sliderStep={10}
              showValue={true}
              valueContent={`${distancePreference}km/${kmToMiles(distancePreference)}mi`}
              showSeparator={true}
							
            /> */}
            {/* <ListItemSecondary
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Distance Preference"
              showDescription={true}
              descriptionContent="Only show people in this range"
              showToggle={true}
              showSeparator={true}
            /> */}
            <ListItemSecondary
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Allow Requests"
              showDescription={true}
              descriptionContent="Accept roses requests from everyone"
              showToggle={true}
            />
          </div>
          <div className="list-container">
            <ListItemSecondary
              showIconLeft={true}
              iconLeftContent={disclaimerIcon}
              showIconBorderLeft={true}
              showLabel={true}
              labelContent="Filters"
              showIconRight={true}
              iconRightContent={chevronRightIcon}
              showValue={true}
              valueContent={"add"}
              showSeparator={true}
            />
            <ListItemSecondary
              showLabel={true}
              labelContent="Age"
              showIconRight={true}
              iconRightContent={closePeachIcon}
              showSlider={true}
              showValue={true}
              valueContent={"21-38"}
              showSeparator={true}
            />
            <ListItemSecondary
              showLabel={true}
              labelContent="Ethnicity"
              showIconRight={true}
              iconRightContent={closePeachIcon}
              showValue={true}
              valueContent={"Latina"}
              showSeparator={true}
            />
            <ListItemSecondary
              showLabel={true}
              labelContent="Language"
              showIconRight={true}
              iconRightContent={closePeachIcon}
              showSlider={true}
              showValue={true}
              valueContent={"21-38"}
              showSeparator={true}
            />
          </div>
        </>
      )}

      <SlidUp
        classNameChildren="list-item-slid-up"
        isOpen={isSlidUpVisible}
        title={titleSlidUp}
      >
        {modalSlidUpContent}
      </SlidUp>

      <SlidUp
        classNameChildren="list-item-slid-up"
        isOpen={isSlidUpWriteVisible}
        title={openWriteInput}
      >
        {openWriteInput === "birthday" ? (
          <InputText
            value={profileBirthday}
            onChangeInput={(e) => setProfileBirthday(e)}
            placeholder="YYYY/MM/DD"
            mask="9999/99/99"
          />
        ) : openWriteInput === "weight" ? (
          <InputText
            value={profileWeight}
            onChangeInput={(e) => setProfileWeight(e)}
            placeholder="Weight"
            // placeholder="YYYY/MM/DD"
            // mask="9999/99/99"
          />
        ) : openWriteInput === "height" ? (
          <InputText
            value={profileHeight}
            onChangeInput={(e) => setProfileHeight(e)}
            placeholder="Height"
            // mask="9999/99/99"
          />
        ) : (
          ""
        )}
        <Button onClick={() => addSpecification()} buttonStyle="primary">
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpWriteVisible(false)}
        >
          Cancel
        </Button>
      </SlidUp>
    </div>
  );
};

export default UserSettingsProfilePage;
