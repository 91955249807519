import React, { useEffect, useState } from "react";
import "./styles.scss";
import HeartPink from "../../../assets/icons/heartIcons/heart-pink.svg";
import HeartWhite from "../../../assets/icons/heartIcons/heart-white.svg";
import HeartPinkSolid from "../../../assets/icons/heartIcons/heart-icon-solid-pink.svg";
import XIconCharcoal from "../../../assets/icons/closeIcons/close-icon-charcoal.svg";
import XIconGray from "../../../assets/icons/closeIcons/close-icon-gray.svg";
import XIconPink from "../../../assets/icons/closeIcons/close-icon-pink.svg";

interface ButtonIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle:
    | "x-gradient"
    | "x-background-transparent"
    | "x-background-charcoal"
    | "x-background-white"
    | "heart"
    | "heart-white"
    | "heart-white-gradient"
	| "heart-pink-gradient"
	| "icon-white";
	
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	
}

const ButtonIcon: React.FC<ButtonIconProps> = (props) => {
  const { buttonStyle, onClick } = props;
	const [className, setClassName] = useState(buttonStyle);
	const [iconClass, setIconClass] = useState(buttonStyle)
  const [altImage, setAltImage] = useState("");
  const [src, setSrc] = useState(buttonStyle);

  useEffect(() => {
    const classStyle = switchButtonStyle()?.className;
    const src = switchButtonStyle()?.icon;
		const alt = switchButtonStyle()?.alt;
		const iconStyle = switchButtonStyle()?.iconClass;
    setClassName(classStyle as any);
    setAltImage(alt as string);
		setSrc(src as any);
		setIconClass(iconStyle as any)
  }, [buttonStyle]);

  const switchButtonStyle = () => {
    switch (buttonStyle) {
      case "x-gradient":
        return { icon: "", alt: "", className: "x-gradient" };
      case "x-background-transparent":
        return {
          icon: XIconPink,
          alt: "close icon",
          className: "x-background-transparent",
        };
      case "x-background-charcoal":
        return {
          icon: XIconGray,
          alt: "close icon",
          className: "x-background-charcoal",
        };
      case "x-background-white":
        return {
          icon: XIconCharcoal,
          alt: "",
          className: "x-background-white",
        };
      case "heart":
        return { icon: "icon-heart-outline", alt: "heart", className: "" };
      case "heart-white":
        return { icon: "icon-heart-outline", alt: "heart", className: "icon-white" };
      case "heart-white-gradient":
        return {
          icon: "icon-heart-outline",
          alt: "heart",
					className: "heart-white-gradient background-blur-gray",
					iconClass: "icon-white"
        };
      case "heart-pink-gradient":
        return {
          icon: "icon-heart-solid",
          alt: "heart",
					className: "heart-pink-gradient background-blur-pink",
        };
    }
  };

  return (
    <button id="button-icon" className={className} onClick={onClick}>
			{/* <img src={src} alt={altImage} className="button-icon__icon"/> */}
			<span
    className={`icon ${src} icon-md ${iconClass}`}></span>
    </button>
  );
};

export default ButtonIcon;
