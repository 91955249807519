import { servicesApi } from "./fetchApi/services-api";

export type MembershipApplicationPhotos = ReturnType<
  ReturnType<typeof CreateChatContact>["postCreateChatContact"]
>;

const CreateChatContact = () => {
  const apiRoute = `${servicesApi("chat")}`;

  const postCreateChatContact = async (userId: string, token: string) => {
    const response = await fetch(`${apiRoute}`, {
      method: "POST",
      body: JSON.stringify({ userId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();

    return { res: data, status: response.status };
  };

  return {
    postCreateChatContact,
  };
};

export default CreateChatContact;
