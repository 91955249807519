import React, { useState } from "react";
import "./styles.scss";

const ProfileDropDown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div id="profile-dropdown" onClick={handleToggle}>
      <div className="dropdown-label">
        <label>Wishlist</label>
      </div>
      <div className={`dropdown-content ${isOpen ? "open" : ""}`}>
        <ul>
          <li>Item 1</li>
          <li>Item 2</li>
          <li>Item 3</li>
          {/* Adicione mais itens conforme necessário */}
        </ul>
      </div>
    </div>
  );
};

export default ProfileDropDown;
