import { useEffect } from "react";
import FooterLP from "../../../components/Footers/FooterLP";
import NavBar from "../../../components/Navbars/NavBar";
import { useLanguage } from "../../../contexts/languageContext";
import "./styles.scss";
import { translateTextNodes } from "../../../utils/translation";

const USCPage: React.FC = () => {
	const { translations } = useLanguage();

	useEffect(() => {
    translateTextNodes(translations);
  }, [translations]);

  useEffect(() => {
    window.scrollTo(0, 0);
	}, []);
	
  return (
    <>
      <NavBar />
      <section id="USC-2257" className="usc-wrapper wrapper padding-top">
        <div className="usc-wrapper-inner inner padding-hor-24">
          <h1 textkey="8/uscTitle">
            <b>USC</b> 2257
          </h1>
          <hr />
          <p className="small-p" textkey="8/uscDescription">
            Roses.Vip is fully compliant with 18 U.S.C. 2257 and all associated
            regulations. All models, actors, actresses, and other persons
            appearing in any visual depiction of actual sexually explicit
            conduct appearing or otherwise contained in this website were over
            the age of eighteen years at the time of the creation of such
            depictions. Records required to be maintained pursuant to 18 U.S.C.
            2257 are kept by the custodian of records, who can be reached at 600
            N BROAD STREET SUITE 5 #3977, MIDDLETOWN, DE, 19709.
          </p>
        </div>
      </section>
      <FooterLP />
    </>
  );
};

export default USCPage;